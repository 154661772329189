import React, {useEffect} from "react";
import Layout2 from "../components/Layout/Layout";
import { Element } from "react-scroll";
import SectionHeading from "../components/Items/SectionHeading";
import Registrod from "../components/Sections/Registro";

function Registros() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout2>
             <section className="shadow-blue white-bg padding mt-0">
             <SectionHeading title="REGISTRO DE EMPRESAS" />
       
        <Registrod />
   
      </section>
    </Layout2>
  );
}

export default Registros;
