import React from "react";
import { Link } from "react-router-dom";
import Blog from "../Items/Blog";

const blogsData = [
  {
    id: 1,
    title: "AmiGGoapp lidera la revolución apostando por la inclusión",
    category: "Blog",
    featureImage: "images/blog/emp2.jpg",
    date: "24 Agosto 2023",
    author: "Amiggoapp",
    slug: "amiggo-empresas-garantizado",
  },
  {
    id: 2,
    title: "Amiggoapp estrena IA para usuarios, conoce a AMEL-IA",
    category: "Blog",
    featureImage: "images/blog/2.jpg",
    date: "24 Agosto 2023",
    author: "Amiggoapp",
    slug: "nueva-ia-amiggoapp",
  },
  {
    id: 3,
    title: "AmiGGoapp Empresas: Revolucionando la Movilidad",
    category: "B2B",
    featureImage: "images/blog/emp4.jpg",
    date: "11 Septiembre 2023",
    author: "AmiGGoapp",
    slug: "amiggoapp-empresas-revolucion",
  },
];

function Blogs() {
  return (
    <>
      <div className="row">
        {blogsData.map((blog) => (
          <div className="col-md-4" key={blog.id}>
            <Blog blog={blog} />
          </div>
        ))}
      </div>
      <div className="spacer" data-height="50"></div>
      <div className="text-center">
        <Link to="/blogs" className="btn btn-default">
          Mostrar todas
        </Link>
      </div>
    </>
  );
}

export default Blogs;
