// En tu componente o archivo
async function fetchData() {
    try {
        const response = await fetch('/globals.json');
        const data = await response.json();
    
        // Ahora, 'data' contiene el contenido de tu archivo JSON
        console.log(data);
        return data;
    } catch (error) {
        console.error('Error al cargar el archivo JSON', error);
        throw error; // Propaga el error para que pueda ser manejado por el código que llama a fetchData si es necesario
    }
}

// Llama a la función para cargar los datos y exporta la promesa
const GLOBALS = fetchData();

export default GLOBALS;