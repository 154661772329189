import React, { useEffect, useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link, useRouteMatch } from "react-router-dom";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaDribbble,
  FaLinkedin,
  FaWhatsapp,
} from "react-icons/fa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCarRear, faUser, faFileCirclePlus } from '@fortawesome/free-solid-svg-icons'




const headerData = {
  name: "EMPRESAS",
  designation: "MOVILIDAD GRATIS GARANTIZADA",
  imageThumb: "/images/logo.png",
  imageThumbMb: "/images/logo_mb.png",
  imageBack: "/images/fondo.png",
  social: {
    facebook: "https://facebook.com",
    twitter: "https://twitter.com",
    instagram: "https://www.instagram.com/",
    youtue: "#",
    dribbble: "https://dribbble.com/",
  },
};

function Header({ logged, toggleHeader, toggleHandler }) {
  const [currentPath, setCurrentPath] = useState("");
  const match = useRouteMatch();

  useEffect(() => {
    setCurrentPath(match.path);
  }, [match]);

  return (
    <>
      <div 
        className={
          toggleHeader
            ? "mobile-header py-2 px-3 mt-4 push"
            : "mobile-header py-2 px-3 mt-4"
        }
      >
        <button className="menu-icon mr-2" onClick={toggleHandler}>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <Link to="/" className="logo">
        &nbsp;&nbsp;&nbsp;<img src={headerData.imageThumbMb} alt={headerData.name} />
        </Link>
        <Link to="/" className="site-title dot ml-2">
          {headerData.name}
        </Link>
        {logged? (<div className="botonesnav"><Link to="/contact" className="Perfil" ><FontAwesomeIcon icon={faFileCirclePlus} className="botonesawesome"  /></Link>&nbsp;&nbsp;&nbsp;&nbsp;
        <a href="/works"><FontAwesomeIcon icon={faCarRear} className="botonesawesome" /></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a href="/multipage"><FontAwesomeIcon icon={faUser} className="botonesawesome"/></a>&nbsp;&nbsp;</div>) : <span></span>}
      </div>

      <header style={{ backgroundImage: "url(/images/fondo.png)" }}
        className={
          toggleHeader
            ? "left float-left shadow-dark open"
            : "left float-left shadow-dark"
        }
      >
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={toggleHandler}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="header-inner d-flex align-items-start flex-column">
          <Link to="/">
            <img src={headerData.imageThumb} alt={headerData.name} />
          </Link>
          <Link to="/" className="site-title dot mt-3">
            {headerData.name}
          </Link>

          <span className="site-slogan">{headerData.designation}</span>

          <nav>
            <ul className="vertical-menu scrollspy">
              <li>
                {currentPath === "/" ? (
                  <ScrollLink
                    activeClass="active"
                    to="section-home"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                  >
                    <i className="icon-home"></i>Inicio
                  </ScrollLink>
                ) : (
                  <Link to="/">
                    <i className="icon-home"></i>Inicio
                  </Link>
                )}
              </li>
          
              <li>
                {currentPath === "/" ? (
                  <ScrollLink
                    activeClass="active"
                    to="section-services"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                  >
                    <i className="icon-bulb"></i>Definición
                  </ScrollLink>
                ) : (
                  <Link to="/">
                    <i className="icon-bulb"></i>Definición
                  </Link>
                )}
              </li>
              <li>
                {currentPath === "/" ? (
                  <ScrollLink
                    activeClass="active"
                    to="section-experiences"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                  >
                    <i className="icon-graduation"></i>Cómo funciona
                  </ScrollLink>
                ) : (
                  <Link to="/">
                    <i className="icon-graduation"></i>Cómo funciona
                  </Link>
                )}
              </li>
         
              <li>
                {currentPath === "/" ? (
                  <ScrollLink
                    activeClass="active"
                    to="section-blogs"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                  >
                    <i className="icon-pencil"></i>Novedades
                  </ScrollLink>
                ) : (
                  <Link to="/">
                    <i className="icon-pencil"></i>Novedades
                  </Link>
                )}
              </li>
              <li>
                {currentPath === "/" ? (
                  <ScrollLink
                    activeClass="active"
                    to="section-contact"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                  >
                    <i className="icon-phone"></i>Contactar
                  </ScrollLink>
                ) : (
                  <Link to="/">
                    <i className="icon-phone"></i>Contactar
                  </Link>
                )}
              </li>
            </ul>
          </nav>
          <div className="footer mt-auto">
            <nav>
            <ul className="vertical-menu scrollspy">
            <li className="list-inline-item">Sesión</li>
            {logged ? (<li>
                {currentPath == "/multipage" ? (
                  <ScrollLink
                    activeClass="active"
                    to="section-about"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                  >
                    <FontAwesomeIcon icon={faUser} /> &nbsp;Perfil
                  </ScrollLink>
                ) : (
                  <Link to="/multipage">
                     <FontAwesomeIcon icon={faUser} /> &nbsp;Perfil
                  </Link>
                )}
              </li>) : (<span></span>)}
              {logged ? (<li>
                {
                 
                  <Link to="/contact">
                     <FontAwesomeIcon icon={faFileCirclePlus} /> &nbsp;Reparaciones
                  </Link>
                }
              </li>) : (<span></span>)}
              {logged ? (<li>
                {
                  <Link to="/works">
                     <FontAwesomeIcon icon={faCarRear} /> &nbsp;Vehículos
                  </Link>
                }
              </li>) : <span></span>}
              {!logged ? (<li>
                {
                  <Link to="/resume">
                    <FontAwesomeIcon icon={faUser} /> &nbsp;Entrar
                  </Link>
                }
              </li>) : <span></span>}
            </ul>
          </nav>



            <span className="copyright">
            </span>
          </div>
         <Link to="/tyc">
          Términos y Condiciones
        </Link> 
        <Link to="/politicas-privacidad">
          Políticas de Privacidad
        </Link>
        </div>
      </header>
    </>
  );
}

export default Header;
