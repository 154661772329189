import React, { useEffect } from "react";
import { Element } from "react-scroll";
import Layout from "../components/Layout/Layout2";
import About from "../components/Sections/About";
import Skills from "../components/Sections/Skills";

import Testimonials from "../components/Sections/Testimonials";


import SectionHeading from "../components/Items/SectionHeadingPerfil";


function Multipage() {
  useEffect(() => {
    window.scrollTo(0, -50);
  }, []);
  return (
    <Layout>
     
      <Element name="section-about">
        <section className="shadow-blue white-bg padding">
          <SectionHeading title="Perfil de Empresa" />
          <About />
        </section>
      </Element>

      {/* <Element name="section-skills">
        <section className="shadow-blue white-bg padding">
          <SectionHeading title="Estadísticas" />
          <Skills />
        </section>
      </Element>



      <Element name="section-testimonials">
        <section className="shadow-blue white-bg padding">
          <SectionHeading title="Listado de empleados" />
          <Testimonials />
        </section>
      </Element> */}

    </Layout>
  );
}

export default Multipage;
