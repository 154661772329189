import React, { useEffect, useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link, useRouteMatch } from "react-router-dom";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaDribbble,
  FaLinkedin,
  FaWhatsapp,
} from "react-icons/fa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCarRear, faUser, faFileCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { Tooltip as ReactTooltip } from "react-tooltip";


const headerData = {
  name: "EMPRESAS",
  designation: "MOVILIDAD GRATIS GARANTIZADA",
  imageThumb: "/images/logo.png",
  imageThumbMb: "/images/logo_mb.png",
  imageBack: "/images/fondo.png",
  social: {
    facebook: "https://facebook.com",
    twitter: "https://twitter.com",
    instagram: "https://www.instagram.com/",
    youtue: "#",
    dribbble: "https://dribbble.com/",
  },
};

function Header({ toggleHeader, toggleHandler }) {
  const [currentPath, setCurrentPath] = useState("");
  const match = useRouteMatch();

  useEffect(() => {
    setCurrentPath(match.path);
  }, [match]);

  return (
    <>
      <div 
        className={
          toggleHeader
            ? "mobile-header py-2 px-3 mt-4 push"
            : "mobile-header py-2 px-3 mt-4"
        }

      >
        
        <Link to="/" className="logo site-title dot">
        &nbsp;&nbsp;&nbsp;<img src={headerData.imageThumbMb} alt={headerData.name} />
        </Link>
        {/* <Link to="/" className="site-title dot ml-2">
          {headerData.name}
        </Link> */}
        <div className="botones"><Link to="/contact" className="Perfil"><FontAwesomeIcon icon={faFileCirclePlus} className="botonesawesome"  /></Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a href="/works"><FontAwesomeIcon icon={faCarRear} className="botonesawesome" /></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a href="/multipage"><FontAwesomeIcon icon={faUser} className="botonesawesome"  /></a></div>
      </div>

      <header style={{ backgroundImage: "url(/images/fondo.png)" }}
        className={
          toggleHeader
            ? "login py-2 px-3 mt-4 push"
            : "login py-2 px-3 mt-4"
        }
      >
        <Link to="/" className="logo">
        &nbsp;&nbsp;&nbsp;<img className="logohead" src={headerData.imageThumbMb} alt={headerData.name} />
        </Link>
    
        <div className="botones bothead"><a href="/contact" className="btn btn-outline-amiggo" data-tooltip-id="my-tooltip-1"><FontAwesomeIcon icon={faFileCirclePlus} className="botonesawesome"  /><span className="tooltip-s1-text tooltip-text "> Reparaciones</span></a>
      
        <a href="/works" className="btn btn-outline-amiggo " data-tooltip-id="my-tooltip-2"><FontAwesomeIcon icon={faCarRear} className="botonesawesome"  /><span className="tooltip-s1-text tooltip-text "> Vehículos</span></a>
        <a href="/multipage" className="btn btn-outline-amiggo "  data-tooltip-id="my-tooltip-3"  ><FontAwesomeIcon icon={faUser} className="botonesawesome"/><span className="tooltip-s1-text tooltip-text "> Perfil</span></a>&nbsp;&nbsp;&nbsp;&nbsp;
      
        </div>
        <ReactTooltip
        id="my-tooltip-1"
        place="bottom"
        variant="info"
        content="Inicia el proceso de solicitud de vehiculo de sustitución"
      />
       <ReactTooltip
        id="my-tooltip-2"
        place="bottom"
        variant="info"
        content="En esta sección gestionas el estado de tus vehículos"
      />
           <ReactTooltip
        id="my-tooltip-3"
        place="bottom"
        variant="info"
        content="Gestiona los datos de tu cuenta de usuario empresas"
      />
      </header>
    </>
  );
}

export default Header;
