import React, { useEffect, useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import GET from "../../utils/get";
import deleteCookie from "../../utils/deleteCookie";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCarRear, faUser, faFileCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from "react-router-dom";



// {
//   "id": self.id,
//   "name": self.name,
//   "email": self.email,
//   "phone": f"+{self.phone_prefix.prefix} {self.phone}",
//   "profile_image": self.profile_image
// }

function About() {
  const [userData,setUserData] = useState({});

  useEffect(() => {
    GET("/get_user",{},{
      200: body => {
        setUserData(body);
      }
    });
    
  },[]);

  const history = useHistory();

    return (
      <div className="row">
        <div className="col-md-2">
          <img src={userData.profile_image} alt={userData.name} />
        </div>
        <div className="col-md-9">
          <h2 className="mt-4 mt-md-0 mb-4">Bienvenido {userData.name},</h2>
          <p className="mb-0"></p>
          <div className="row my-4">
            <div className="col-md-6">
              {/* <p className="mb-2">
                DELEGADO: <span className="text-dark">-</span>
              </p> */}
              <p className="mb-0">
                Contraseña: <span className="text-dark"> &nbsp; <a href="/cambiapass" className="cambio">Cambiar</a></span>
              </p>
            </div>
            <div className="col-md-6 mt-2 mt-md-0 mt-sm-2">
              {/* <p className="mb-2">
                LUGAR: <span className="text-dark">-</span>
              </p> */}
              <p className="mb-0">
                EMAIL: <span className="text-dark">{userData.email}</span><br />
                
                Accede a tus <b><a href="/metodos-pago">métodos de pago</a>.</b>
              </p>
            </div>
          </div>
          <p>Con AmiGGoapp Empresas podrás gestionar las reparaciones de tu flota y obtener las ventajas de días de vehículos de alquiler de remplazo en simples pasos:<br/>
        <br/> <ul>
            <li>Primero da de alta tus vehículos enviando todas las matrículas que desees poder gestionar:
              <br/>
              <a href="/Services" className="btn btn-default mr-3">
              <FontAwesomeIcon icon={faCarRear} className=""  />&nbsp; Añadir matrículas
          </a>
          <p> &nbsp;</p>
              </li>
            <li>Envía los documentos correspondientes al leasing de cada vehículo de tu flota, así estarán disponibles para obtener vehículo de alquiler de sustitución cuando tengan que repararse en el taller por accidentes:
            <br/>
          {/* <a onClick={() => GET("/mycars/excel",{},{
            200: async buffer => {
              
            }
          },true)} className="btn btn-default mr-3">
            <i className="icon-cloud-download"></i>Descargar lista de vehículos
          </a> */}
          <a href="/leasing?type=leasing" className="btn btn-default mr-3">
            <i className="icon-book-open"></i>Enviar documentación leasing
          </a>
          <p> &nbsp;</p></li>
          <li>En caso de siniestro, en el apartado <b><a href="/works">Mis Vehículos</a></b> elige la matrícula del vehículo afectado para notificar una reparación y conseguir un ticket AmiGGoapp de alquiler de vehículo de remplazo. 
          <br/>
              <a href="/Contact" className="btn btn-default mr-3">
              <FontAwesomeIcon icon={faFileCirclePlus} className=""  />&nbsp; Notificar reparación
          </a>
          <p> &nbsp;</p></li>
          <li>Sube la documentación del siniestro, como fotografías del mismo, el recibo de la grúa y el parte amistoso o de atestado:
            <br/>
            <a href="/leasing?type=siniestro" className="btn btn-default mr-3">
            <i className="icon-book-open"></i>Enviar documentación siniestro y grúa
          </a>
          <p> &nbsp;</p></li>
          <li>Cuando la reparación haya terminado, envía la documentación de fin de reparación y el recibo del taller:
            <br/>
            <a href="/leasing?type=reparacion" className="btn btn-default mr-3">
            <i className="icon-book-open"></i>Subir documentación fin de reparación 
          </a>
          <p> &nbsp;</p></li>
          <li>Cuando el coche haya sido reparado, indícalo en el listado <b><a href="/works">Mis Vehículos</a></b> para terminar el alquiler de sustitución, así el alquiler no se renovará automáticamente.

          <p> &nbsp;</p>
          </li>
          </ul></p>
        <p> &nbsp;</p>
        <p> &nbsp;</p>
          <a onClick={() => deleteCookie("session_id")} className="btn btn-default btn-default-close mr-3">
            <i className="bi bi-0-circle"></i>Cerrar Sesión
          </a>
        </div>
      </div>
    );
}

export default About;
