import React, { useState } from "react";
import GET from "../../utils/get";
import POST from "../../utils/post";

const contactData = {
  phone: ["+34 682 106 333"],
  email: ["empresas@amiggoapp.com"],
  location: "Chatear con AmiGGoapp",
};

function Contact() {
  const [formdata, setFormdata] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  const submitHandler = (event) => {
    event.preventDefault();
    if (!formdata.name) {
      setError(true);
      setMessage("Se requiere un nombre");
    } else if (!formdata.phone) {
      setError(true);
      setMessage("Se requiere un teléfono");
    } else if (!formdata.email) {
      setError(true);
      setMessage("Se requiere un email");
    } else if (!formdata.message) {
      setError(true);
      setMessage("Se requiere un mensaje");
    } else {
      POST("/send_mail",{"subject": formdata.name+" con correo "+formdata.email+" solicita información",
          "message": formdata.message+"\n\nNOMBRE: "+formdata.name+"\nTELÉFONO: "+formdata.phone+"\nCORREO: "+formdata.email},{
            200: body => {
              setError(false);
              setMessage("Su mensaje ha sido enviado");
            },
            0: () => {
              setError(true);
              setMessage("Email no enviado");
            }
          });
      
    }
  };

  const handleChange = (event) => {
    setFormdata({
      ...formdata,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleAlerts = () => {
    if (error && message) {
      return <div className="alert alert-danger mt-4">{message}</div>;
    } else if (!error && message) {
      return <div className="alert alert-success mt-4">{message}</div>;
    } else {
      return null;
    }
  };

  return (
    <div className="row">
      <div className="col-md-4 mb-4 mb-md-0">
        
        <div className="contact-info mb-5">
        <h6>¿Dudas? Contacta...</h6><br></br>
          <i className="icon-phone"></i>
          <div className="details">
            <h5>Por teléfono</h5>
            {contactData.phone.map((singlePhone, index) => (
              <span key={index}><a href="tel:+34682106333">{singlePhone}</a></span>
            ))}
          </div>
        </div>
        <div className="contact-info mb-5">
          <i className="icon-envelope"></i>
          <div className="details">
            <h5>Por correo</h5>
            {contactData.email.map((singleEmail, index) => (
              <span key={index}><a href="mailto:empresas@amiggoapp.com">{singleEmail}</a></span>
            ))}
          </div>
        </div>
        <div className="contact-info">
          <i className="icon-location-pin"></i>
          <div className="details">
            <h5>Por Whatsapp</h5>
            <span><a href="https://wa.me/34682106333">{contactData.location}</a></span>
          </div>
        </div>
      </div>

      <div className="col-md-8">
        <form className="contact-form" onSubmit={submitHandler}>
          <div className="row">
            <div className="column col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="NOMBRE"
                  onChange={handleChange}
                  value={formdata.name}
                />
              </div>
            </div>

            <div className="column col-md-6">
              
              <div className="form-group">
                <input
                  type="tel"
                  className="form-control"
                  name="phone"
                  placeholder="TELÉFONO"
                  onChange={handleChange}
                  value={formdata.phone}
                />
              </div>
            </div>

            <div className="column col-md-12">
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="EMAIL"
                  onChange={handleChange}
                  value={formdata.email}
                />
              </div>
            </div>

            <div className="column col-md-12">
              <div className="form-group">
                <textarea
                  name="message"
                  className="form-control"
                  rows="5"
                  placeholder="COMENTARIOS"
                  onChange={handleChange}
                  value={formdata.message}
                ></textarea>
              </div>
            </div>
          </div>

          <button
            type="submit"
            name="submit"
            value="Submit"
            className="btn btn-default"
          >
            <i className="icon-paper-plane"></i>ENVIAR
          </button>
        </form>
        {handleAlerts()}
      </div>
    </div>
  );
}

export default Contact;
