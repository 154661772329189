import getCookie from './getCookie';
import GLOBALS from './globals';
const fetch = require('node-fetch'); // <- cargamos el módulo http
/**
 * @param {string} path - ruta (sin dominio)
 * @param {object} args - diccionario de parámetros get de la petición
 * @param {object} onResponse - diccionario de function(body) cuyas keys son los códigos HTTP, el código 0 corresponde al default (los códigos que no se manejen individualmente pasarán a ser manejados por la función asociada al código 0 Y NO RECIBEN NINGÚN PARÁMETRO POR LA FUNCIÓN)
 */
async function WGET(path,onResponse){
  const globals = await GLOBALS;
  let requestOptions = {
    crossDomain: true,
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: {
      "Access-Control-Allow-Origin": globals.backend_url_base,
      "Content-Type": "text/html",
      "Access-Control-Allow-Credentials": "true"
    }
  };
  if(document.cookie.includes("session_id")) requestOptions.headers.Authentication = getCookie("session_id");

  try {
      const res = await fetch(`${globals.backend_url_base}${path}`, requestOptions);
    
      if (res.status in onResponse) {
        onResponse[res.status](await res.text());
      } else if (0 in onResponse) {
        onResponse[0]();
      }
    } catch (e) {
      if (0 in onResponse) {
        onResponse[0]();
      }
    }
}

export default WGET;