import React from "react";
import Experience from "../Items/Experience";

const experiencesData = [
  {
    id: 1,
    year: "icon-user",
    degree: "Dar de alta su empresa en AmiGGoapp",
    content:
      "Las empresas se registran como usuario en la web de AmiGGoapp Empresas con un proceso rápido y gratuito, proporcionando información básica de la compañía y de los vehículos de su flota, adjuntando también la documentación correspondiente de cada vehículo. AmiGGoapp lo pone en bandeja, solicite el alta hoy y su flota estará muy pronto asegurada.",
  },
  {
    id: 2,
    year: "icon-map",
    degree: "Informe de las reparaciones de su flota",
    content:
      "Informe a través de nuestra webapp acerca de la avería de un vehículo de su flota, proceda a llevarlo al taller de su conveniencia (servicio oficial de Renting/Leasing) con el fin de realizar la reparación necesaria. Esta acción activará automáticamente la posibilidad de acceder a un vehículo de sustitución Garantizado y Gratis.",
  },
  {
    id: 3,
    year: "icon-calendar",
    degree: "Disfrute de vehículos de sustitución",
    content:
      "AmiGGoapp proporcionará un vehículo de sustitución Garantizado. La empresa puede utilizar este vehículo gratuitamente durante los primeros 3 días, y si es necesario, extender el período de alquiler a un precio imbatible.",
  },
];

function Experiences() {
  return (
    <div className="timeline">
      {experiencesData.map((experience) => (
        <Experience experience={experience} key={experience.id} />
      ))}
      <span className="timeline-line"></span>
    </div>
  );
}

export default Experiences;
