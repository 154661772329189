import React from "react";
import Button from "react-bootstrap/Button";
import { confirm } from '../../utils/confirmation'


function Service({ service: { popup, name, content, icon, header} }) {

  return (
    <div className="service-item text-center" onClick={async event => await confirm(popup, true, name, true, header)}>
      <img src={`/images/def/${icon}_icono.png`}/> 
      <h4 className="my-3">{name}</h4>
      <p className="mb-0">{content}</p>
    </div>
  );
}

export default Service;
