import React, { useState, useEffect } from "react";
import Portfolio from "../Items/Portfolio";
import GET from "../../utils/get";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { parseFilter, filters } from "../../utils/vehicleFilters";
import { confirm } from '../../utils/confirmation'
import { Link } from "react-router-dom";

const allData = [];

function Portfolios() {

  const [getAllItems, setAllItems] = useState([]);
  const [dataVisibleCount, setDataVisibleCount] = useState(0);
  const [dataIncrement] = useState(4);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);
  const [noMorePost, setNoMorePost] = useState(false);

  const [showTable, setShowTable] = useState(false);
  const [showGrid, setShowGrid] = useState(true);

  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  const toggleTable = () => {
    let newDataVisibleCount;
    if (!showTable) // ahora habrá tabla
      newDataVisibleCount = getAllItems.length;
    else // ahora habrá grid
      newDataVisibleCount = Math.min(dataIncrement*2,getAllItems.length);

    setDataVisibleCount(newDataVisibleCount);
    handleChange(activeFilter,newDataVisibleCount);
    setShowTable(!showTable);
    setShowGrid(!showGrid);
  };

  useEffect(() => {
    GET("/get_cars",{},{
      200: body => {
        setAllItems(body);
        setActiveFilter(filters[0].value);
        setVisibleItems(body.slice(0,dataIncrement*2));
        setDataVisibleCount(Math.min(body.length,dataIncrement*2));
        setNoMorePost(body.length <= Math.min(body.length,dataIncrement*2));
      }
    })
    
  }, []);
  

  const handleChange = (targetFilter,dataVisibleCount) => {
    setActiveFilter(targetFilter);
    let tempData;
    if (targetFilter == "all") {
      tempData = getAllItems;
    } else {
      tempData = getAllItems.filter((data) => {
        return (
          data.state == targetFilter
        );
      })
    }
    setVisibleItems(tempData.slice(0,dataVisibleCount));
    setNoMorePost(tempData.length <= dataVisibleCount);
  };

  const handleLoadmore = (e) => {
    e.preventDefault();
    let allItems = activeFilter == "all" ? getAllItems : getAllItems.filter((data) => {
      return (
        data.state == activeFilter
      );
    });
    let tempCount = dataVisibleCount + dataIncrement;
    if (dataVisibleCount >= allItems.length)
      return;
    tempCount = Math.min(dataVisibleCount+dataIncrement,allItems.length);
    setDataVisibleCount(tempCount);
    setVisibleItems(allItems.slice(0,tempCount));
    if (tempCount >= allItems.length) {
      setNoMorePost(true);
    } 
  };

  const handleAlerts = () => {
    if (error && message) {
      return <div className="alert alert-danger mt-4">{message}</div>;
    } else if (!error && message) {
      return <div className="alert alert-success mt-4">{message}</div>;
    } else {
      return null;
    }
  };

  const handleOnClickDisable = async (event, plate) => {
    event.preventDefault();
    let text = "Si deshabilita un vehículo sólo podrá habilitarlo de nuevo poniéndose en contacto con nuestra administración";
  
    if (await confirm(text, true, "¿Estás Seguro?", false, "", true, "", true, "OK", "Cancelar")) {
      GET("/remove_car",{"plate":plate},{
        200: body => {
          setError(false);
          setMessage("Vehículo con matrícula " + plate + " deshabilitado con éxito");
          setTimeout(function(){
            window.location.reload();
          }, 3200);
        },
        400: body => {
          setError(true);
          setMessage(body.error);
        }
      })
    }
  }

  const handleOnClickReturned = async (event, plate) => {
    event.preventDefault();
    let text = "Está a punto de declarar que ha devuelto el vehículo de sustitución. Pulse OK sólo si ya ha efectuado esta devolución.";
  
    if (await confirm(text, true, "Atención", false, "", true, "", true, "OK", "Cancelar")) {
      GET("/notify_return",{"plate":plate},{
        200: body => {
          setError(false);
          setMessage("Devolución del vehículo con matrícula " + plate + " notificada con éxito");
          setTimeout(function(){
            window.location.reload();
          }, 3200);
        },
        400: body => {
          setError(true);
          setMessage(body.error);
        }
      })
    }
  }

  const handleOnClickEnlarge = async (event, plate, creation_date, num_extensions) => {
    event.preventDefault();
    let text = "Seleccione el número de semanas (períodos de 7 días naturales), por los que quiere renovar su ticket.";
  
    let weeks;
    let span;
    let now;
    const refreshDate = () => {

      weeks = document.getElementById("modal-input").value;
      span = document.getElementById("modal-input-span");
      
      console.log(creation_date);

      let start = new Date(Date.parse(creation_date));
      start.setDate(start.getDate() + 3 + (num_extensions * 7));

      now = new Date(start);
      now.setDate(now.getDate() + (weeks * 7));
      span.innerHTML = "Si pulsa OK, renovaría desde el día: <br/><b>" + Intl.DateTimeFormat('es-ES', { dateStyle: 'full'}).format(start)
        + "</b><br/>a las <b>" + Intl.DateTimeFormat('es-ES', {hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(start)
        + "</b><br/>hasta el día:<br/><b>" + Intl.DateTimeFormat("es-ES", { dateStyle: 'full'}).format(now) + "</b>"
        + "</b><br/>a las <b>" + Intl.DateTimeFormat('es-ES', {hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(now);
    }

    if (await confirm(text, true, "Renovación Ticket", false, "", true, "", true, "OK", "Cancelar", "number", refreshDate)) {
      GET("/expand_ticket",{"plate":plate, "n_expansions":weeks, "final_date": Intl.DateTimeFormat('es-ES', {day:'2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3, timeZone: 'GMT'}).format(now)},{
        200: body => {
          setError(false);
          setMessage("Ha ampliado su ticket para el vehículo con matrícula " + plate + " en " + weeks + " semanas con éxito");
          setTimeout(function(){
            window.location.reload();
          }, 3200);
        },
        400: body => {
          setError(true);
          setMessage(body.error);
        }
      })
    }
  }

  function expiryDate(creation_date, num_extensions){
    const start = new Date(Date.parse(creation_date));
    start.setDate(start.getDate() + 3 + (num_extensions * 7));
    return Intl.DateTimeFormat('es-ES', {day:'2-digit', month: '2-digit', year: 'numeric'}).format(start);
  }

  return (
    <>

<div className="botones">
      &nbsp;&nbsp;&nbsp; <button className="btn" onClick={toggleTable}>
        {showTable ? <i className="icon-grid bononesicon" title="Mostrar Fichas"></i>
 : <i className="icon-list bononesicon" title="Mostrar Tabla"></i>}
      </button>
      </div>

      <p>&nbsp;</p>
        {handleAlerts()}
        
      <ul className="portfolio-filter list-inline">
        {filters.map((filter) => (
          <li
            className={
              filter.value === activeFilter
                ? "list-inline-item current"
                : "list-inline-item"
            }
            key={filter.id}
            onClick={() => handleChange(filter.value,dataVisibleCount)}
          >
            {filter.name}
          </li>
        ))}
      </ul>

      {showTable && (
          <Table>
          <Thead>
            <Tr>
            <Th></Th>
              <Th>Matricula</Th>
             
              <Th>Estado</Th>
              {activeFilter == "onrepair" || activeFilter == "all" ?(<Th>Caduca</Th>):<Th></Th>}
              
            </Tr>
          </Thead>
          <Tbody>
            {visibleItems.map(
              (item) => (
                <Tr key={item.id}>
                   {item.state != "onrepair" && !item.pending_repair?(<Td><a className="plus-icon-table plus-icon-delete" onClick={event => handleOnClickDisable(event, item.plate)}><span>&nbsp;X&nbsp;</span></a></Td>):(<Td></Td>)}
                  
                 <Td><span className="matri"><strong>{item.plate.toUpperCase()}</strong></span></Td>
              
                  <Td><img src={`images/portfolio/${item.state}.png`} width={'199px'} title={parseFilter(item.state)}/></Td>
                  {(activeFilter == "onrepair" || activeFilter == "all") && item.state == "onrepair" && !item.notified ?(<Td>{expiryDate(item.report.creation_date, item.report.num_extensions)}</Td>):null}
                  {item.state == "available" ? (<Td><a className="plus-icon-table" href={`contact/?plate=${item.plate}`}><span>&nbsp;Notificar&nbsp;Reparación&nbsp;</span></a></Td>) 
                   : (item.state == "onrepair" && !item.notified && item.report.status == "accepted"? (<Td><a className="plus-icon-table" onClick={event => handleOnClickReturned(event, item.plate)}><span>&nbsp;Finalizar&nbsp;Alquiler&nbsp;</span></a></Td>)
                   : (item.state == "onrepair" && !item.notified && item.report.status == "waiting"?(<Td><Link className="plus-icon-table" to={"/about/"+item.report.id}><span>&nbsp;Reservar&nbsp;Vehículo&nbsp;</span></Link></Td>):(null)))}
                  {item.state == "onrepair" && !item.notified? (<Td><a className="plus-icon-table" onClick={event => handleOnClickEnlarge(event, item.plate, item.report.creation_date, item.report.num_extensions)}><span>&nbsp;Ampliar&nbsp;Alquiler&nbsp;</span></a></Td>): (null)}
                  {item.state == "onrepair" && !item.report.is_verified? (<Td><a className="plus-icon-table" href={`/leasing/?plate=${item.plate}&type=siniestro`}><span>&nbsp;Enviar&nbsp;Siniestro&nbsp;</span></a></Td>):(null)}    
                  {item.state == "pending"? (<Td><a className="plus-icon-table" href={`/leasing/?plate=${item.plate}&type=leasing`}><span>&nbsp;Enviar&nbsp;Leasing&nbsp;</span></a></Td>):(null)} 
                  {item.pending_repair? (<Td><a className="plus-icon-table" href={`/leasing/?plate=${item.plate}&type=reparacion`}><span>&nbsp;Informe&nbsp;Reparación&nbsp;</span></a></Td>):(null)}
                 
                </Tr>
              )
            )}
          </Tbody>
        </Table>
      )}


      <div className="pf-filter-wrapper mb-4">
        <select
          className="portfolio-filter-mobile"
          onChange={(e) => handleChange(e,dataVisibleCount)}
        >
          {filters.map((filter) => (
            <option value={filter.value} key={filter.id}>
              {filter.name}
            </option>
          ))}
        </select>
      </div>

      {showGrid && (

      <div className="row portfolio-wrapper">
        {visibleItems.map((item) => (
          <div className="col-md-3 col-sm-6 grid-item" key={item.id}>
            <Portfolio portfolio={item} />
          </div>
        ))}
      </div>
      
      )}

      {noMorePost ? null : (
        <div className="load-more text-center mt-4">

{showGrid && (  
          <a
            href="#!"
            className="btn btn-default"
            onClick={(e) => handleLoadmore(e)}
          >
            <i className="fas fa-circle-notch"></i> Ver más vehícuos
          </a>
          )}
        </div>
      )}
    </>
  );
}


export default Portfolios;
