import React from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCarRear, faUser, faFileCirclePlus, faVideo } from '@fortawesome/free-solid-svg-icons'

const herosectionData = {
  name: "AMIGGOAPP",
  aboutMe:
    "Con AMIGGOAPP EMPRESAS, aseguramos que las OPERACIONES COMERCIALES de nuestros clientes no se interrumpan con vehículos de sustitución gratuitos, garantizando movilidad y eficiencia mientras sus vehículos están en reparación.",
};

function Herosection({ logged }) {
  return (
    <section
      className="hero background parallax shadow-dark d-flex align-items-center"
      style={{ backgroundImage: "url(/images/hero.jpg)" }}
    >
      <div className="cta mx-auto mt-2">
        <h1 className="mt-0 mb-4">
          Movilidad asegurada
          <span className="dot"></span>
        </h1>
        <p className="mb-4">{herosectionData.aboutMe}</p>
        <div className="row -mt-20">
        <div className="col-d-4 col-sm-6 mt-20">
        <div className="service-item text-center intermedio">
      <h4 className="my-3">GRATIS</h4>
      <p className="mb-0">Proporcionamos vehículos de sustitución sin coste, asegurados a todo riesgo y sin franquicia por 3 días, tras un accidente.</p>
    </div>
        </div>
        <div className="col-d-4 col-sm-6 mt-20">
        <div className="service-item text-center intermedio">
      <h4 className="my-3">GARANTIZADA</h4>
      <p className="mb-0">Eliminamos el riesgo de interrupciones en operaciones comerciales, garantizando su flujo de trabajo y compromisos.</p>
    </div>
        </div>
    </div>
    <p>&nbsp;</p>
        {logged ? (<Link to="/works"    className="btn btn-default btn-lg mr-3">
                  <FontAwesomeIcon icon={faCarRear} />&nbsp;&nbsp;Vehículos
                  </Link>) : (<span></span>)}
        {logged ? (<div
          className="spacer d-md-none d-lg-none d-sm-none"
          data-height="10"
        ></div>) : (<span></span>)}
   
        {logged ? (<Link to="/contact"    className="btn btn-border-light btn-lg">
                  <FontAwesomeIcon icon={faFileCirclePlus}/>&nbsp;&nbsp;Reparaciones
                  </Link>) : (<span></span>)}

        {logged ? (<div className="d-inline-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>) : (<span></span>)}          

        {logged ? (<Link to="/multipage"    className="btn btn-border-light btn-lg">
                  <FontAwesomeIcon icon={faUser}/>&nbsp;&nbsp;Perfil
                  </Link>) : (<span></span>)}
        {!logged ? (<Link to="/resume"    className="btn btn-border-light btn-lg">
                  <FontAwesomeIcon icon={faUser}/>&nbsp;&nbsp;Entrar               </Link> ) : (<span></span>)}
        {!logged ? (<a href="https://youtu.be/NkU1_-6QQWw?si=0h8SpZiik0-j-4mZ"    className="btn btn-border-light btn-lg" target="_blank">
                  <FontAwesomeIcon icon={faVideo}/>&nbsp;&nbsp;Ver Video               </a> ) : (<span></span>)}
        {!logged ? (  <ScrollLink
                    className="btn btn-border-light btn-lg derecha nomovil"
                    activeClass="active"
                    to="section-services"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                  >
                    <FontAwesomeIcon icon={faFileCirclePlus}/>&nbsp;&nbsp;Saber más   </ScrollLink>) : (<span></span>)}
        
      </div>
      
      <div className="overlay"></div>
    </section>
  );
}

export default Herosection;
