import React, {useEffect} from "react";
import Layout2 from "../components/Layout/Layout2";
import SectionHeading from "../components/Items/SectionHeading";

function StripeSuccess(props) {
    const session_id = props.match.params.session_id;

    return (
    <Layout2>
    <section className="shadow-blue white-bg padding mt-0">
        <SectionHeading title="Operación realizada" />
        <p>Tu forma de pago ha sido actualizada.</p>
        <p>Volver a tus <b><a href="/metodos-pago">Métodos de pago</a>.</b></p>
      </section>
   

    </Layout2>
    )
}

export default StripeSuccess;
