import React, { useState } from "react";
import {parseFilter} from "../../utils/vehicleFilters";
import GET from "../../utils/get";
import { confirm } from '../../utils/confirmation';
import { Link } from "react-router-dom";


function Portfolio({ portfolio: { id, plate, brand, model, state, city, warehouse, image, notified, report, pending_repair } }) {

  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  const handleOnClickReturned = async (event, plate) => {
    event.preventDefault();
    let text = "Está a punto de declarar que ha devuelto el vehículo de sustitución. Pulse OK sólo si ya ha efectuado esta devolución.";
  
    if (await confirm(text, true, "Atención", false, "", true, "", true, "OK", "Cancelar")) {
      GET("/notify_return",{"plate":plate},{
        200: body => {
          setError(false);
          setMessage("Devolución del vehículo con matrícula " + plate + " notificada con éxito");
          setTimeout(function(){
            window.location.reload();
          }, 2700);
        },
        400: body => {
          setError(true);
          setMessage(body.error);
        }
      })
    }
  }

  function expiryDate(creation_date, num_extensions){
    const start = new Date(Date.parse(creation_date));
    start.setDate(start.getDate() + 3 + (num_extensions * 7));
    return Intl.DateTimeFormat('es-ES', {day:'2-digit', month: '2-digit', year: 'numeric'}).format(start);
  }

  const handleOnClickDisable = async (event, plate) => {
    event.preventDefault();
    let text = "Si deshabilita un vehículo sólo podrá habilitarlo de nuevo poniéndose en contacto con nuestra administración";
  
    if (await confirm(text, true, "¿Estás Seguro?", false, "", true, "", true, "OK", "Cancelar")) {
      GET("/remove_car",{"plate":plate},{
        200: body => {
          setError(false);
          setMessage("Vehículo con matrícula " + plate + " deshabilitado con éxito");
          setTimeout(function(){
            window.location.reload();
          }, 3200);
        },
        400: body => {
          setError(true);
          setMessage(body.error);
        }
      })
    }
  }

  const handleOnClickEnlarge = async (event, plate, creation_date, num_extensions) => {
    event.preventDefault();
    let text = "Seleccione el número de semanas (períodos de 7 días naturales), por los que quiere renovar su ticket.";
  
    let weeks;
    let span;
    let now;
    const refreshDate = () => {

      weeks = document.getElementById("modal-input").value;
      span = document.getElementById("modal-input-span");
      
      console.log(creation_date);

      let start = new Date(Date.parse(creation_date));
      start.setDate(start.getDate() + 3 + (num_extensions * 7));

      now = new Date(start);
      now.setDate(now.getDate() + (weeks * 7));
      span.innerHTML = "Si pulsa OK, renovaría desde el día: <br/><b>" + Intl.DateTimeFormat('es-ES', { dateStyle: 'full'}).format(start)
        + "</b><br/>a las <b>" + Intl.DateTimeFormat('es-ES', {hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(start)
        + "</b><br/>hasta el día:<br/><b>" + Intl.DateTimeFormat("es-ES", { dateStyle: 'full'}).format(now) + "</b>"
        + "</b><br/>a las <b>" + Intl.DateTimeFormat('es-ES', {hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3}).format(now);
    }

    if (await confirm(text, true, "Renovación Ticket", false, "", true, "", true, "OK", "Cancelar", "number", refreshDate)) {
      GET("/expand_ticket",{"plate":plate, "n_expansions":weeks, "final_date": Intl.DateTimeFormat('es-ES', {day:'2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3, timeZone: 'GMT'}).format(now)},{
        200: body => {
          setError(false);
          setMessage("Ha ampliado su ticket para el vehículo con matrícula " + plate + " en " + weeks + " semanas con éxito");
          setTimeout(function(){
            window.location.reload();
          }, 3200);
        },
        400: body => {
          setError(true);
          setMessage(body.error);
        }
      })
    }
  }

  const handleAlerts = () => {
    if (error && message) {
      return <div className="alert alert-danger mt-4">{message}</div>;
    } else if (!error && message) {
      return <div className="alert alert-success mt-4">{message}</div>;
    } else {
      return null;
    }
  };

  let body = (
    <div className="portfolio-item">
      <div className="details">
        <h4 className="title">{plate.toUpperCase()}</h4>
        <span className="term">{parseFilter(state)}</span>
        {state == "onrepair" && !notified?<span className="term">hasta el {expiryDate(report.creation_date, report.num_extensions)}</span>:<span></span>}
        {/* <span className="term">{city}</span> */}
      </div>
      {state == "available" ? (<span className="plus-icon">Notificar</span>) : (state == "onrepair" && !notified?(<span className="plus-icon">Gestionar</span>) : (<span className="plus-icon">Documentación</span>))}
      <div className="thumb">
      <img src={`images/portfolio/${state}.png`} />
        <img src={image != null ? image : "images/portfolio/sub.png"} alt={plate} />
        <h2>&nbsp;<span className="verde">{plate.replace( /[^\D].*/, '' ).toUpperCase()}</span>{plate.match(/\d+/).shift()}<span className="verde">{plate.replace( /.*[^\D]/, '' ).toUpperCase()}</span></h2>
        <div className="mask"></div>
      </div>   
    </div>
  );

  let makereport = (<p>Para notificar una reparación haz click en el siguiente botón.<br/><a className="plus-icon-table"  href={`contact/?plate=${plate}`}>&nbsp; NOTIFICAR REPARACIÓN &nbsp;</a><br/>&nbsp;<br/></p>);
  let enlargeRenting =  (<p>Si vas a ampliar el período de tu alquiler, haz click a continuación.<br/><a className="plus-icon-table"onClick={event => handleOnClickEnlarge(event, plate, report.creation_date, report.num_extensions)}><span>&nbsp; AMPLIAR ALQUILER &nbsp;</span></a></p>);
  let returnVehicle = (<p>Si has devuelto el vehículo de alquiler de remplazo, haz click en el siguiente botón.<br/><a className="plus-icon-table "  onClick={event => handleOnClickReturned(event, plate)}>&nbsp; FINALIZAR ALQUILER &nbsp;</a></p>);
  let uploadSiniestro = (<p>Para subir fotos del siniestro, parte de grúa y el parte amistoso/atestado, haz click en el siguiente botón.<br/><a className="plus-icon-table"  href={`/leasing/?plate=${plate}&type=siniestro`}>&nbsp; Enviar documentación del siniestro &nbsp;</a></p>);
  let uploadLeasing = (<p>Para enviarnos documentación del leasing o seguro, haz click a continuación.<br/><a className="plus-icon-table"  href={`/leasing/?plate=${plate}&type=leasing`}>&nbsp; Enviar documentación Leasing &nbsp;</a></p>);
  let finalRepair = (<p>Para enviar la documentación de la reparación de tu vehículo haz click en el siguiente botón.<br/><a className="plus-icon-table "  href={`/leasing/?plate=${plate}&type=reparacion`}> &nbsp; Subir Informe Reparación &nbsp;</a></p>);
  let deshabilitar = (<p>Para deshabilitar el vehículo haz click en el siguiente botón.<br/><a className="plus-icon-table plus-icon-delete" onClick={event => handleOnClickDisable(event, plate)}>Deshabilitar</a></p>);
  let caducidad = (<h4><b>Estado:</b> {parseFilter(state)} | Matrícula: {plate}</h4>);


  let newVar = <span></span>;
  let topVar = <span></span>;

  if (state == "available")
    newVar = makereport;
  else if(state == "onrepair")
  {
    if(!notified && report.status == "accepted")
    {
      caducidad = (<h4><b>{plate}:</b> {parseFilter(state)} hasta el {expiryDate(report.creation_date, report.num_extensions)}</h4>);
      newVar = (<div>{enlargeRenting}{returnVehicle}</div>);
    }
    else if(!notified && report.status == "waiting")
    {
      let reservar = (<p>Para reservar tu vehículo de sustitución haz click en el siguiente botón.<br/><a className="plus-icon-table" href={"/about?id="+report.id}>&nbsp; Reservar Vehículo &nbsp;</a></p>);
      newVar = (<div>{reservar}</div>);
    }
    if(!report.is_verified)
      newVar = (<div>{newVar}{uploadSiniestro}</div>);
  }   
  else if(state == "pending")
    newVar = uploadLeasing;

  if(pending_repair)
    newVar = (<div>{newVar}{finalRepair}</div>);

  if(state != "onrepair" && !pending_repair)
    newVar = (<div>{newVar}<br/><hr/>{deshabilitar}</div>);
    
  topVar = caducidad;
    
  return (
    <a onClick={async event => await confirm(newVar, true, (<h3>Gestionar reparaciones y alquiler</h3>), true, topVar)} >
      {body}
    </a>
  );
}

export default Portfolio;