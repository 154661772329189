import React, {useEffect} from "react";
import Layout2 from "../components/Layout/Layout2";
import SectionHeading from "../components/Items/SectionHeading";

function StripeCancel(props) {
    const session_id = props.match.params.session_id;

    return (
    <Layout2>
  <section className="shadow-blue white-bg padding mt-0">
        <SectionHeading title="Error" />
        <p>Tu forma de pago ha fallado.</p>
      </section>

    </Layout2>
    )
}

export default StripeCancel;