import React, { useState, useEffect } from "react";
import Layout from "../components/Layout/Layout"; //Use and import Layout2 when you use multipage
import Blog from "../components/Items/Blog";
import Pagination from "../components/Items/Pagination";

const blogsData = [
  {
    id: 1,
    title: "AmiGGoApp Lidera la Revolución en Movilidad apostando por la inclusión",
    category: "Blog",
    featureImage: "images/blog/emp2.jpg",
    date: "24 Agosto 2023",
    author: "Amiggoapp",
    slug: "amiggo-empresas-garantizado",
  },
  {
    id: 2,
    title: "Amiggoapp estrena IA para usuarios, conoce a AMEL-IA",
    category: "Blog",
    featureImage: "images/blog/2.jpg",
    date: "24 Agosto 2023",
    author: "AmiGGoapp",
    slug: "nueva-ia-amiggoapp",
  },


  {
    id: 3,
    title: "AmiGGoApp Empresas: Revolucionando la Movilidad",
    category: "B2B",
    featureImage: "images/blog/emp4.jpg",
    date: "11 Septiembre 2023",
    author: "AmiGGoapp",
    slug: "amiggoapp-empresas-revolucion",
  },
  {
    id: 4,
    title: "Términos y condiciones",
    category: "TyC",
    featureImage: "images/blog/tyc.jpg",
    date: "17 Diciembre 2023",
    author: "AmiGGoapp",
    slug: "terminos-y-condiciones",
  },
  {
    id:5,
    title: "Políticas de privacidad",
    category: "Políticas",
    featureImage: "images/blog/tyc.jpg",
    date: "17 Diciembre 2023",
    author: "AmiGGoapp",
    slug: "politicas-privacidad",
  },
];

function Bloglist() {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);

  useEffect(() => {
    setPosts(blogsData);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  };

  return (
    <Layout>
      <section className="shadow-blue white-bg padding mt-0">
        <div className="row -mt-50">
          {currentPosts.map((blog) => (
            <div className="col-md-6 mt-50" key={blog.id}>
              <Blog blog={blog} />
            </div>
          ))}
        </div>
        <div className="spacer" data-height="50"></div>
        {!(posts.length > postsPerPage) ? null : (
          <Pagination
            itemsPerPage={postsPerPage}
            totalItems={posts.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        )}
      </section>
    </Layout>
  );
}

export default Bloglist;
