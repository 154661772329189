import React, { useState, useEffect } from "react";
import Layout2 from "../components/Layout/Layout2";
import Blog2 from "../components/Items/Blog2";
import Pagination from "../components/Items/Pagination";

const blogsData = [
  {
    id: 1,
    title: "El coste de los vehículos nuevos se dispara",
    category: "Mercado",
    featureImage: "images/blog/1.jpg",
    date: "03 Julio 2023",
    author: "Jaime Carvajal",
    slug: "the-truth-about-design",
  },
  {
    id: 2,
    title: "Amiggoapp estrena IA, conoce a AMEL-IA",
    category: "Blog",
    featureImage: "images/blog/2.jpg",
    date: "24 Agosto 2023",
    author: "Amiggoapp",
    slug: "the-ugly-truth-about-design",
  },
  {
    id: 3,
    title: "El coste de los vehículos nuevos se dispara",
    category: "Mercado",
    featureImage: "images/blog/3.jpg",
    date: "03 Julio 2023",
    author: "Jaime Carvajal",
    slug: "the-truth-about-design",
  },
  {
    id: 4,
    title: "El coste de los vehículos nuevos se dispara",
    category: "Mercado",
    featureImage: "images/blog/emp1.jpg",
    date: "03 Julio 2023",
    author: "Jaime Carvajal",
    slug: "the-truth-about-design",
  },
  {
    id: 5,
    title: "AmiGGoApp Lidera la Revolución en Movilidad apostando por la inclusión con una Solución Garantizada y Gratuita",
    category: "Blog",
    featureImage: "images/blog/emp2.png",
    date: "24 Agosto 2023",
    author: "Amiggoapp",
    slug: "amiggo-empresas-garantizado",
  },
  {
    id: 6,
    title: "Nuevos acuerdos AmiggoApp con Proveedores movilidad",
    category: "B2B",
    featureImage: "images/blog/emp3.jpg",
    date: "11 Septiembre 2023",
    author: "Juan M Varela",
    slug: "how-to-become-better-with-ui-design",
  },
  {
    id: 9,
    title: "Nuevos acuerdos AmiggoApp con Proveedores movilidad",
    category: "B2B",
    featureImage: "images/blog/tyc.jpg",
    date: "11 Septiembre 2023",
    author: "Juan M Varela",
    slug: "how-to-become-better-with-ui-design",
  },
];

function Bloglist2() {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setPosts(blogsData);
  }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  };

  return (
    <Layout2>
      <section className="shadow-blue white-bg padding mt-0">
        <div className="row -mt-50">
          {currentPosts.map((blog) => (
            <div className="col-md-6 mt-50" key={blog.id}>
              <Blog2 blog={blog} />
            </div>
          ))}
        </div>
        <div className="spacer" data-height="50"></div>
        {!(posts.length > postsPerPage) ? null : (
          <Pagination
            itemsPerPage={postsPerPage}
            totalItems={posts.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        )}
      </section>
    </Layout2>
  );
}

export default Bloglist2;
