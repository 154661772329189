import GLOBALS from './globals';
import getCookie from './getCookie';
const fetch = require('node-fetch');

async function isLogged () {
    let requestOptions = {
        crossDomain: true,
        method: 'GET',
        mode: 'cors',
        cache: 'default',
        headers:{}
    };
    const globals = await GLOBALS;
    if(document.cookie.includes("session_id")) requestOptions.headers.Authentication = getCookie("session_id");
    let res = await fetch(globals.backend_url_base+"/api/amilogged",requestOptions);
    return res.status == 200;
}

export default isLogged;