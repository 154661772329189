const filters = [
    {
      id: 1,
      name: "Todos",
      value: "all"
    },
    {
      id: 2,
      name: "No verificados",
      value: "pending"
    },
    {
      id: 3,
      name: "En reparación",
      value: "onrepair"
    },
    {
      id: 4,
      name: "Disponibles",
      value: "available"
    }
];
const filterDict = {
    "pending": 1,
    "onrepair": 2,
    "available": 3
};

function parseFilter(filter) {return filters[filterDict[filter]].name;}

export {parseFilter,filters};