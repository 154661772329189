import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.scss";

import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Multipage from "./pages/Multipage";
import Bloglist from "./pages/Bloglist";
import Bloglist2 from "./pages/Bloglist2";
import BlogDetails from "./pages/BlogDetails";
import BlogDetails2 from "./pages/BlogDetails2";
import About from "./pages/About";
import Services from "./pages/Services";
import Resume from "./pages/Resume";
import Registro from "./pages/Registro";
import Works from "./pages/Works";
import Contact from "./pages/Contact";
import cambiapass from "./pages/cambiapass";
import leasing from "./pages/leasing";
import Demopage from "./pages/Demopage";
import StripeSuccess from "./pages/StripeSuccess";
import StripeCancel from "./pages/StripeCancel";
import MetodosPago from "./pages/MetodosPago";
import PP from "./pages/PP";
import TyC from "./pages/TyC";
import WorkDetails from "./pages/WorkDetails";
import WorkDetails2 from "./pages/WorkDetails2";
import isLogged from "./utils/isLogged";
import FormatExcelPage from "./pages/FormatExcel";

function App() {
  const [logged,setIsLogged] = useState(false);
  const [loaded,setIsLoaded] = useState(false)

  useEffect(() => {
    async function AskQuestion(){
      try {
        if (await isLogged())
          setIsLogged(true);
      }finally {
        setIsLoaded(true);
      }
    }
    AskQuestion();
  },[])
  
  if (!loaded)
    return (
      <p></p>
  );
  if(!logged){
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" component={()=>Homepage(logged)} exact />

          <Route path="/blogs" component={Bloglist} exact />
          <Route path="/bloglist" component={Bloglist2} exact />
          <Route path="/blogs/:id/:title" component={BlogDetails} />
          <Route path="/blog-details/:id/:title" component={BlogDetails2} />
          <Route path="/resume" component={Resume} exact />
          <Route path="/registro" component={Registro} exact />
          <Route path="/utils/excel_formatter" component={FormatExcelPage} />
          <Route path="/tyc" component={TyC} exact />
          <Route path="/politicas-privacidad" component={PP} exact />
          <Route path="/cambiapass" component={()=>cambiapass(logged)} exact />
          

          {/* For Multipage */}
          <Redirect from="/stripe-success" to="/resume" />
          <Redirect from="/stripe-cancel" to="/resume" />
          <Redirect from="/metodos-pago" to="/resume" />
          <Redirect from="/multipage" to="/resume" />
          <Redirect from="/about" to="/resume" />
          <Redirect from="/services" to="/resume"t />
          <Redirect from="/works" to="/resume" />
          <Redirect from="/works/:id/:title" to="/resume" />
          <Redirect from="/work-details/:id/:title" to="/resume" />
          <Redirect from="/contact" to="/resume" />
          <Redirect from="/leasing" to="/resume" />
        </Switch>
      </BrowserRouter>
    );
  }
  else return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={()=>Homepage(logged)} exact />

        <Route path="/blogs" component={Bloglist} exact />
        <Route path="/bloglist" component={Bloglist2} exact />
        <Route path="/blogs/:id/:title" component={BlogDetails} />
        <Route path="/blog-details/:id/:title" component={BlogDetails2} />
        <Redirect from="/resume" to="/multipage" />
        <Redirect from="/registro" to="/multipage" />
        <Route path="/utils/excel_formatter" component={FormatExcelPage} />
        <Route path="/tyc" component={TyC} exact />
        <Route path="/politicas-privacidad" component={PP} exact />
        <Route path="/cambiapass" component={()=>cambiapass(logged)} exact />

        {/* For Multipage */}
        <Route path="/stripe-success" component={StripeSuccess} exact />
        <Route from="/stripe-cancel" component={StripeCancel} exact />
        <Route from="/metodos-pago" component={MetodosPago} exact />
        <Route path="/multipage" component={Multipage} exact />
        <Route path="/about" component={About} exact />
        <Route path="/services" component={()=>Services(logged)} exact />
        <Route path="/works" component={Works} exact />
        <Route path="/works/:id/:title" component={WorkDetails} />
        <Route path="/work-details/:id/:title" component={WorkDetails2} />
        <Route path="/contact" component={Contact} exact />
        <Route path="/leasing" component={leasing} exact />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
