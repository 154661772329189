import React, {useEffect} from "react";
import Layout2 from "../components/Layout/Layout";
import SectionHeading from "../components/Items/SectionHeading";
import ContactSection from "../components/Sections/Alta_vehiculos";

function Services(logged) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout2 logged={logged}>
      <section className="shadow-blue white-bg padding mt-0">
        <SectionHeading title="ALTA DE VEHÍCULOS" />
        <ContactSection />
      </section>
    </Layout2>
  );
}

export default Services;
