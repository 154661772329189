import React, { useState } from 'react';
import * as XLSX from 'xlsx';

function FormatExcelPage() {
  const [unformattedData, setUnformattedData] = useState('');
  const [reformattedData1, setReformattedData1] = useState([]);
  const [reformattedData2, setReformattedData2] = useState([]);
  const keys = [
    "Póliza",
    "Tomador",
    "NIF",
    "F. Efecto",
    "C. Producción",
    "Teléfono",
    "Dir. Corresp",
    "Excluido",
  ];

  const handleDataChange = (event) => {
    setUnformattedData(event.target.value);
  };

  const listOfDictsToCsv = (listOfDicts) => listOfDicts.map(dict => (
    keys.map(key => {
      console.log(`${key}:${dict[key]}`);
      if (typeof dict[key] === 'string' && dict[key].includes(',')) {
        return `"${dict[key]}"`;
      }
     
      return dict[key].toString();
    }).join(',')
  )).join('\n');

  const searchDate = (elements) => {
    for (let i = 0; i < elements.length; i++) {
      try {
        const date = Date.parse(elements[i]);
        if (!isNaN(date)) {
          return [date,i];
        } else {
        }
      } catch (error) {
      }
    }
    return [null,1];
  };

  const downloadXLSXFromJSON = (data) => {
    // Crear un libro de trabajo (workbook)
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);

    console.log(worksheet);

    // Agregar la hoja de cálculo al libro de trabajo
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Hoja1');

    // Convertir el libro de trabajo a una matriz de bytes
    const excelBinary = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Crear un Blob con los datos binarios
    const blob = new Blob([excelBinary], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // Manejar Internet Explorer
      window.navigator.msSaveOrOpenBlob(blob, 'ejemplo.xlsx');
    } else {
      // Crear un objeto URL para el Blob y generar el enlace de descarga
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'ejemplo.xlsx'; // Nombre del archivo Excel
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      // Liberar el objeto URL
      URL.revokeObjectURL(url);
    }
  }

  const reformatData = (unformat) => {
    try {
      const elements = unformat.split(' ');
      let poliza = elements[0];
      console.log("poliza: "+poliza);
      let fEfectoEIndex = searchDate(elements);
      console.log("fEfecto: "+fEfectoEIndex[0]);
      let nif = elements[fEfectoEIndex[1]-1];
      console.log("NIF: "+nif);
      let cProduccion = elements[fEfectoEIndex[1]+1];
      console.log("cProduccion: "+cProduccion);
      let telefono = elements[fEfectoEIndex[1]+1].replace("TELEFONO...:","");
      console.log("telefono: "+telefono);
      let excluido = elements[elements.length-1];
      console.log("excluido: "+excluido);
      let tomador = elements.slice(1,fEfectoEIndex[1]-1).join(" ");
      console.log("tomador: "+tomador);
      let dirCorresp = elements.slice(fEfectoEIndex[1]+3,elements.length-1).join(" ");
      console.log("dirCorresp: "+dirCorresp);
      const result = {
        "Póliza": poliza,
        "Tomador": tomador,
        "NIF": nif,
        "F. Efecto": fEfectoEIndex[0],
        "C. Producción": cProduccion,
        "Teléfono": telefono,
        "Dir. Corresp": dirCorresp,
        "Excluido": excluido,
      };

      return result;
    } catch (error) {
        console.log("Oye he petado");
        console.log(unformat);
      return null;
    }
  };

  const handleReformatting = () => {
    const lines = unformattedData.replace(/\r/g, '').split('\n');
    console.log(lines.length);
    const formattedData1 = lines.map((item) => reformatData(item));
    const formattedData2 = unformattedData.replace(/\r/g, '').replace(/\n/g, '').split('  ').map((item) => reformatData(item));
    downloadXLSXFromJSON(formattedData1.length > formattedData2.length ? formattedData1 : formattedData2)
    setReformattedData1(formattedData1);
    setReformattedData2(formattedData2);
  };

  return (
    <div>
      <h1>Reformatting Data</h1>
      <textarea
        placeholder="Insert unformatted data here..."
        rows="10"
        cols="150"
        value={unformattedData}
        onChange={handleDataChange}
      /><br/>
      <button onClick={handleReformatting}>Reformat Data</button>
      {/* <div>
        <h2>Reformatted Data</h2>
        <pre>{JSON.stringify(reformattedData1.length > reformattedData2.length ? reformattedData1 : reformattedData2, null, 2)}</pre>
      </div> */}
    </div>
  );
}

export default FormatExcelPage;