import React from "react";

function SectionHeading({ title }) {
  return (
    <>
     <div className="vehiculos"><div className="titulo"> <h3 className="section-title">{title}</h3></div>
     <div className="botones">
      {/* <a href="/services"><i className="icon-plus bononesicon"></i></a>
    
      &nbsp;&nbsp;&nbsp;<i className="icon-list bononesicon"></i> */}
     
     </div></div>
      <div className="spacer" data-height="80"></div>
    </>
  );
}

export default SectionHeading;
