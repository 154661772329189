import React, {useEffect} from "react";
import Layout2 from "../components/Layout/Layout2";
import SectionHeading from "../components/Items/SectionHeading";
import WidgetSection from "../components/Sections/widgetEn";
import Testimonials from "../components/Sections/Testimonials";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
    
  }, []);

  const hasId = new URLSearchParams(document.location.search).has("id");
  const id = new URLSearchParams(document.location.search).get("id");

  if(hasId)
    return (
      <Layout2>
        <section className="shadow-blue white-bg padding mt-0">
          <SectionHeading title="Alquiler AmiGGoapp" />
          <WidgetSection id={id}/>
        </section>


        <section className="shadow-blue white-bg padding">
          <SectionHeading title="Reservas anteriores" />
          <Testimonials />
        </section>

    
      </Layout2>
    );
  return (<div><p>403 Forbidden</p></div>);
}

export default About;
