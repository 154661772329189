
import React, { useState, useEffect } from "react";
import GET from "../../utils/get";

const contactData = {
  phone: ["+34 682 106 333"],
  email: ["empresas@amiggoapp.com"],
  location: "Chatear con AmiGGoapp",
};

function Contact() {
  const [formdata, setFormdata] = useState({
    name: new URLSearchParams(document.location.search).has("plate") ? new URLSearchParams(document.location.search).get("plate"): ""
  });

  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  const [availableVehicles, setAvailableVehicles] = useState([]);

  useEffect(() => {
    GET("/get_available_cars",{},{
      200: body => {
        setAvailableVehicles(body);
      }
    })
    
  }, []);

  const submitHandler = (event) => {
    event.preventDefault();
    if (!formdata.name) {
      setError(true);
      setMessage("Definir la matricula es obligatorio");
    } else {
      GET("/create_report",{"plate": formdata.name},{
        200: body => {
          setError(false);
          setMessage("Parte efectuado correctamente, recibirás un mail con los detalles de tu ticket");
          //formdata.name = "";
          //setFormdata(formdata);
        },
        400: body => {
          setError(true);
          setMessage(body.error);
        }
      })
    }
  };

  const handleChange = (event) => {
    setFormdata({
      name: event.currentTarget.value,
    });
  };

  const handleAlerts = () => {
    if (error && message) {
      return <div className="alert alert-danger mt-4">{message}</div>;
    } else if (!error && message) {
      return <div className="alert alert-success mt-4">{message}</div>;
    } else {
      return null;
    }
  };

  return (
    <div className="row reverse">
      <div className="col-md-4 mb-4 mb-md-0">
        
      <div className="contact-info mb-5">
        <h6>¿Dudas? Contacta...</h6><br></br>
          <i className="icon-phone"></i>
          <div className="details">
            <h5>Por teléfono</h5>
            {contactData.phone.map((singlePhone, index) => (
              <span key={index}><a href="tel:+34682106333">{singlePhone}</a></span>
            ))}
          </div>
        </div>
        <div className="contact-info mb-5">
          <i className="icon-envelope"></i>
          <div className="details">
            <h5>Por correo</h5>
            {contactData.email.map((singleEmail, index) => (
              <span key={index}><a href="mailto:empresas@amiggoapp.com">{singleEmail}</a></span>
            ))}
          </div>
        </div>
        <div className="contact-info">
          <i className="icon-location-pin"></i>
          <div className="details">
            <h5>Por Whatsapp</h5>
            <span><a href="https://wa.me/34682106333">{contactData.location}</a></span>
          </div>
        </div>
        <p>&nbsp;</p>
      </div>

      <div className="col-md-8">
      <h5>Sólo te queda un paso para notificar la reparación</h5><p>Comprueba la matrícula o selecciona una en el siguiente desplegable para finalizar la notificación de la reparación del vehículo, y así <strong>recibir el ticket AmiGGoapp de coche de alquiler de remplazo</strong>.</p>
        <form className="contact-form" onSubmit={submitHandler}>
          <div className="row">
            <div className="column col-md-6">
              <div className="form-group">
                {/*<input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="MATRÍCULA DEL VEHÍCULO"
                  onChange={handleChange}
                  value={formdata.name}
            />*/}

                <input
                type="text"
                className="form-control"
                list="exampleList"
                placeholder="MATRÍCULA DEL VEHÍCULO"
                onChange={handleChange}
                value={formdata.name}
                />
                <datalist id="exampleList">
                  {availableVehicles.map((vehicle) => (
                    <option value={vehicle.plate} key={vehicle.id}></option>
                  ))}
                </datalist>
              </div>
            </div>

          </div>
                <p> {handleAlerts()}</p>
          <button
            type="submit"
            name="submit"
            value="Submit"
            className="btn btn-default"
          >
            <i className="icon-paper-plane"></i>Finalizar parte
          </button>
        </form>
        <p>&nbsp;</p> <p>&nbsp;</p>
       
      </div>
    </div>
  );
}

export default Contact;


