import React, { useEffect, useState } from "react";
import GET from "../../utils/get";
import WGET from "../../utils/wget";
import { useHistory } from "react-router-dom";



// {
//   "id": self.id,
//   "name": self.name,
//   "email": self.email,
//   "phone": f"+{self.phone_prefix.prefix} {self.phone}",
//   "profile_image": self.profile_image
// }

function About({ id }) {
  const [userData,setUserData] = useState({});
  const [iFrameContent, setIFrameContent] = useState('');

  useEffect(() => {
    GET("/get_user",{},{
      200: body => {
        setUserData(body);
      }
    });
  }, []);
  useEffect(() => {
    WGET("/external/enterprise/"+id,{
      200: body => {
        setIFrameContent(body);
      }
    })
  },[id])

  const history = useHistory();

  return (
    <div className="row">
      <div className="col-md-2">
       <h3>Haz tu reserva</h3>
   <p className="mb-0">
         <br/>1.- Reserva mínimo 3 días de coche de sustitución GRATIS GARANTIZADO. <br/> <br/>
        <p>2.- Selecciona la oficina de recogida y el día de inicio de tu reserva.</p>
        <p>3.- También puedes añadir más tiempo a tu alquiler a un precio imbatible a razón de +7 días, de cualquier manera los 3 primeros días serán gratis.</p>
      </p>
      </div>
      <div className="col-md-9">
        <iframe 
          sandbox="allow-same-origin allow-scripts"
          width={700}
          height={600}
          className="sinborde"
          srcDoc={iFrameContent}
        ></iframe>
        {/* <iframe src={"https://empresasdev.amiggoapp.com:5000/external/enterprise/"+id} width={700} height={600} class="sinborde"></iframe> */}
       <p> &nbsp;</p>
      </div>
    </div>
  );
}

export default About;
