import React from "react";
import Service from "../Items/Service";


const servicesData = [
  {
    id: 1,
    name: "Aplicación",
    content: "Webapp integral a su alcance: gestione servicios gratis de manera eficiente y garantizada.",
    popup: (<div><img src='images/def/a.webp'/><p>&nbsp;</p><p>A través de un proceso de registro sencillo en nuestra plataforma web, las empresas pueden solicitar de manera eficiente un vehículo de sustitución, asegurando una respuesta rápida a sus necesidades.</p><p>&nbsp;</p></div>),
    header:(<div><p>Empresas AmiGGoapp, movilidad Garantizada Gratis</p></div>),
    icon: "aplicacion",
  },
  {
    id: 2,
    name: "Movilidad",
    content: "Tus actividades comerciales diarias nunca se verán afectadas con la solución de movilidad de AmiGGoapp.",
    popup: (<div><img src='images/def/m.webp'/><p><p>&nbsp;</p>Tus actividades comerciales diarias nunca se verán afectadas con la solución de movilidad garantizada y gratis de AmiGGoapp.<br/>Acceso constante a vehículos de reemplazo, asegurando movilidad sin interrupciones para su equipo.<p>&nbsp;</p></p><br/></div>),
    header:(<div><p>Empresas AmiGGoapp, movilidad Garantizada Gratis</p></div>),
    icon: "movilidad",
  },
  {
    id: 3,
    name: "Instantánea",
    content: "Respuesta rápida y efectiva: soluciones de movilidad disponibles al instante ante interrupciones de movilidad.",
    popup: (<div><p>&nbsp;</p><p>Respuesta rápida y efectiva: soluciones de movilidad disponibles al instante ante sus percances relacionados con la movilidad.<br/>Soluciones de movilidad disponibles al instante para sus necesidades urgentes.<br/>Eliminamos el riesgo de interrupciones en sus operaciones comerciales, permitiendo que su empresa continúe su flujo de trabajo y cumpla con sus compromisos sin contratiempos.</p><br/><p>&nbsp;</p><img src='images/def/i.webp'/></div>),
    header:(<div><p>Empresas AmiGGoapp, movilidad Garantizada Gratis</p></div>),
    icon: "instantanea",
  },
  {
    id: 4,
    name: "Gratis",
    content: "Disfrute de 3 días de vehículo de sustitución Gratis en caso de accidente, con la posibilidad de extender el alquiler a un precio imbatible.",
    popup: (<div><p>Benefíciese de tres días gratuitos con posibilidad de extensión a precios competitivos, reduciendo costos operativos.<br/>Empresas.AmiggoApp.com ofrece, sin coste alguno, vehículos de sustitución completamente asegurados y libres de cualquier franquicia durante 3 días tras un accidente, facilitando una solución inmediata a sus necesidades de movilidad.</p><br/><p>&nbsp;</p><img src='images/def/gr.webp'/></div>),
    header:(<div><p>Empresas AmiGGoapp, movilidad Garantizada Gratis</p></div>),
    icon: "gratis",
  },
  {
    id: 5,
    name: "Garantizada",
    content: "AmiGGoapp es sinónimo de garantía, siempre que necesites movilidad, garantizamos la continuidad de tus operaciones.",
    popup: (<div><p>AmiGGoapp es sinónimo de garantía, siempre que tenga un percance con su movilidad, encontraremos la solución más conveniente para usted.<br/>Fiabilidad asegurada con vehículos de sustitución siempre disponibles, garantizando la continuidad de sus operaciones.</p><br/><p>&nbsp;</p><img src='images/def/ga.webp'/></div>),
    header:(<div><p>Empresas AmiGGoapp, movilidad Garantizada Gratis</p></div>),
    icon: "garantizada",
  },
  {
    id: 6,
    name: "Optimizada",
    content: "Maximice la eficiencia y productividad con una gestión de recursos ágil y optimizada para su negocio..",
    popup: (<div><p>Maximice la eficiencia y productividad con una gestión de recursos ágil y optimizada para su negocio.<br/>Contamos con una extensa red de proveedores en las principales ciudades de España, Alemania, Francia, Inglaterra y Estados Unidos, garantizando que siempre habrá un vehículo disponible cerca de usted, minimizando cualquier posible interrupción en su negocio.</p><br/><p>&nbsp;</p><img src='images/def/o.webp'/></div>),
    header:(<div><p>Empresas AmiGGoapp, movilidad Garantizada Gratis</p></div>),
    icon: "optimizada",
  },
];

function Services() {
  return (
    <div className="row -mt-20">
      {servicesData.map((service) => (
        <div className="col-md-4 col-sm-6 mt-20" key={service.id}>
          <Service service={service} />
        </div>
      ))}
    </div>
  );
}

export default Services;
