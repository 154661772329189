import React, { useState } from "react";
import POST from "../../utils/post";

const contactData = {
  phone: ["+34 682 106 333"],
  email: ["empresas@amiggoapp.com"],
  location: "Chatear con AmiGGoapp",
};

function Contact() {
  const [formdata, setFormdata] = useState({
    name: ""
  });

  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  const submitHandler = (event) => {
    event.preventDefault();
    if (!formdata.message) {
      setError(true);
      setMessage("Introduce al menos una matrícula");
    } else {

      const matriculas = event.target[0].value;
      var arrayMatriculas = matriculas.split(/[\s,]+/);
      arrayMatriculas = arrayMatriculas.filter(plate => plate != ' ' && plate != '');

      const spanishPlate = /^[0-9]{4}[b-df-hj-npr-tv-zB-DF-HJ-NPR-TV-Z]{3}|[a-zA-Z]{1,2}[0-9]{4}[b-df-hj-npr-tv-zB-DF-HJ-NPR-TV-Z]{2}?$/;

      var noFormat = [];

      for(var i=0;i<arrayMatriculas.length;i++){
        if(!spanishPlate.test(arrayMatriculas[i])){
          noFormat.push(arrayMatriculas[i]);
          arrayMatriculas.splice(i,1);
        }
      }


      POST("/addcar",{"plates":arrayMatriculas.join(',').toUpperCase()},{
        200: body => {
          setError(false);
          setMessage("Vehículos Añadidos, ahora dirígete al apartado de mis vehículos para gestionarlos y subir la documentación de cada uno");
        },
        206: body => {
          setError(false);
          setMessage("Algunos Vehículos Añadidos! Estas matrículas no se han añadido por formato incorrecto o porque ya existían en el registro:\n" + body.unavailable_plates.join(", ").toUpperCase() + ", " + noFormat.join(", ").toUpperCase() + ". Sí alguno de estos vehículos existe pero está deshabilitado, póngase en contacto con nosotros para habilitarlo de nuevo.");
        },
        400: body => {
          setError(true);
          if(noFormat.length > 0) setMessage("Las matrículas no se han enviado porque están en formato incorrecto, a saber: " + noFormat.join(", ").toUpperCase());
          else setMessage("No se ha enviado ninguna matrícula");
        }
      })
    }
  };

  const handleChange = (event) => {
    setFormdata({
      ...formdata,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleAlerts = () => {
    if (error && message) {
      return <div className="alert alert-danger mt-4">{message}</div>;
    } else if (!error && message) {
      return <div className="alert alert-success mt-4">{message}</div>;
    } else {
      return null;
    }
  };

  return (
    <div className="row reverse">
      <div className="col-md-4 mb-4 mb-md-0">
        
      <div className="contact-info mb-5">
        <h6>¿Dudas? Contacta...</h6><br></br>
          <i className="icon-phone"></i>
          <div className="details">
            <h5>Por teléfono</h5>
            {contactData.phone.map((singlePhone, index) => (
              <span key={index}><a href="tel:+34682106333">{singlePhone}</a></span>
            ))}
          </div>
        </div>
        <div className="contact-info mb-5">
          <i className="icon-envelope"></i>
          <div className="details">
            <h5>Por correo</h5>
            {contactData.email.map((singleEmail, index) => (
              <span key={index}><a href="mailto:empresas@amiggoapp.com">{singleEmail}</a></span>
            ))}
          </div>
        </div>
        <div className="contact-info">
          <i className="icon-location-pin"></i>
          <div className="details">
            <h5>Por Whatsapp</h5>
            <span><a href="https://wa.me/34682106333">{contactData.location}</a></span>
          </div>
        </div>
        <p>&nbsp;</p>
      </div>

      <div className="col-md-8">
      <h5>Añade las matrículas de los vehículos de tu flota</h5><p>Escribe las matrículas separadas por comas y/o espacios en el siguiente cuadro de entrada:</p>
        <form className="contact-form" onSubmit={submitHandler}>
          <div className="row">
            <div className="column col-md-6">
              <div className="form-group">
              <textarea
                  name="message"
                  className="form-control"
                  rows="5"
                  placeholder="MATRICULAS A DAR DE ALTA"
                  onChange={handleChange}
                  value={formdata.message}
                ></textarea>
              </div>
            </div>
            
          </div>
              <p> {handleAlerts()}</p>
          <button
            type="submit"
            name="submit"
            value="Submit"
            className="btn btn-default"
          >
            <i className="icon-paper-plane"></i>Enviar
          </button>
        </form>
       
        <p>&nbsp;</p> <p>&nbsp;</p>
       
      </div>
    </div>
  );
}

export default Contact;
