import React, { useState, useEffect } from "react";
import GET from "../../utils/get";
import { confirm } from '../../utils/confirmation'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GLOBALS from "../../utils/globals";

function Tarjeta() { 
  const [formdata, setFormdata] = useState({
  });

  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  const [availablePaymentMethods, setAvailablePaymentMethods] = useState([]);
  const [userPaymentToken, setUserPaymentToken] = useState("");

  const [active, setActive] = useState({});

  const [globals, setGlobals] = useState({backend_url_base: ""});

  useEffect( async () => {
    setGlobals(await GLOBALS);   
  }, []);

  useEffect(() => {
    GET("/my_payments",{},{
      200: body => {
        setAvailablePaymentMethods(body);
        for(var i=0;i<availablePaymentMethods.length;++i){
          active[availablePaymentMethods.stripe_id] = false
        }
        // setActive(active)
      }
    })
  }, []);

  useEffect(() => {
    GET("/get_payment_token",{},{
      200: body => {
        setUserPaymentToken(body.token);
      }
    })   
  }, []);

  const handlePreguntaClick = (event) => {
    setActive({
      ...active,
      [event.target.id]:!active[event.target.id],
    });
  };

  const handleOnClickDelete = async (event, pm) => {
    event.preventDefault();
    let text = "Está a punto de deshabilitar su tarjeta " + pm.brand +" - " + pm.card_type + " terminada en ******" + pm.number + ". Pulse OK sólo si está de acuerdo.";
  
    if (await confirm(text, true, "¿Estás Seguro?", false, "", true, "", true, "OK", "Cancelar")) {
      GET("/delete_payment",{"payment_method":pm.stripe_id},{
        200: body => {
          setError(false);
          setMessage("Tarjeta " + pm.brand + " de " + pm.card_type + " terminada en ******" + pm.number + " deshabilitada con éxito");
          setTimeout(function(){
            window.location.reload();
          }, 2700);
        },
        400: body => {
          setError(true);
          setMessage(body.error);
        }
      })
    }
  }

  const handleOnClickDefault = async (event, pm) => {
    event.preventDefault();
    let text = "Está a punto de establecer su tarjeta " + pm.brand +" - " + pm.card_type + " terminada en ******" + pm.number + " por defecto, esto significa que todos los cargos se le empezarán a cobrar a esta. Pulse OK sólo si está de acuerdo.";
  
    if (await confirm(text, true, "¿Estás Seguro?", false, "", true, "", true, "OK", "Cancelar")) {
      GET("/set_default_payment",{"payment_method":pm.stripe_id},{
        200: body => {
          setError(false);
          setMessage("Tarjeta " + pm.brand + " de " + pm.card_type + " terminada en ******" + pm.number + " establecida por defecto con éxito");
          setTimeout(function(){
            window.location.reload();
          }, 2700);
        },
        400: body => {
          setError(true);
          setMessage(body.error);
        }
      })
    }
  }

  const handleAlerts = () => {
    if (error && message) {
      return <div className="alert alert-danger mt-4">{message}</div>;
    } else if (!error && message) {
      return <div className="alert alert-success mt-4">{message}</div>;
    } else {
      return null;
    }
  };

  return (
    <div class="row reverse"><div class="col-md-4 mb-4 mb-md-0">
       
       <p><a href={globals.backend_url_base + "/payment/create/" + userPaymentToken} className="btn btn-default mr-3">
       Añadir Tarjeta
        </a> </p>
        <p>{handleAlerts()}</p>
    </div>

    <div class="col-md-8">
        <h4>ACTIVOS</h4><br/>
        
        <div style={{scrollY: 'scroll'}}>
          {availablePaymentMethods.map((pm) => (
            <div className="row">
              <div className="column mb-5 pregunta">
                <div className="details">
                  <h5><i className="icon-credit-card"></i>  {pm.brand} - {pm.card_type}  {pm.is_default? "(Principal)":""}</h5>
                  <span key={pm.stripe_id}>******{pm.number}</span><br/>
                  <span>Cad. {pm.expiry_month}/{pm.expiry_year}</span>
                  
                </div>
                <a className="button-l" id={pm.stripe_id} onClick={handlePreguntaClick}>Mas detalles<i className={`${active[pm.stripe_id] ? 'icon-arrow-up' : 'icon-arrow-down'}`}></i></a>
                <div className={"respuesta"} style={{ display: active[pm.stripe_id] ? 'block' : 'none' }}>
                  <span>Nombre: {pm.name}</span><br/>
                  <span>País: {pm.country}</span><br/>
                  <span>Dirección Facturación: {pm.billing_address}</span>
                </div> 
              </div>
              <Col className="column mt-6" md={{ span: 3, offset: 3 }}>
                {pm.is_default? (<span></span>):(<a onClick={event => handleOnClickDelete(event, pm)}><i className="icon-close rojo peq"></i></a>)}
                &nbsp;&nbsp;
                {pm.is_default? (<span></span>):(<a onClick={event => handleOnClickDefault(event, pm)}><i className="icon-pin peq"></i></a>)}
              </Col>
            </div>
          ))}
        </div>
       
    </div>
    </div>
  );
}

export default Tarjeta;