import React, { useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";
import GET from "../../utils/get";

const herosectionData = {
  name: "AMIGGOAPP",
  aboutMe:
    "Introduce tus credenciales de AMIGGOAPP EMPRESAS.",
};


function Herosection() {
  const [user,setUser] = useState("");
  const [password,setPassword] = useState("");

  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  const login = () => {
    GET("/login",{"user": user,"password": password},{
      200: body => {

        document.cookie = "session_id=" + body.token ;
        window.location.href = "/multipage";
      },
      400: body => {
        setError(true);
        setMessage(body.error);
      },
      401: body => {
        setError(false);
        setMessage(body.error);
      }
    })
  }

  const handleAlerts = () => {
    if (error && message) {
      return <div className="alert alert-danger mt-4">{message}</div>;
    } else if (!error && message) {
      return <div className="alert alert-success mt-4">{message}</div>;
    } else {
      return null;
    }
  };


  const handleChangeUser = (e) => {
    setUser(e.currentTarget.value);
  };

  const handleChangePassword = (e) => {
    setPassword(e.currentTarget.value);
  };

  return (
    <section
      className="hero background parallax shadow-dark d-flex align-items-center"
      style={{ backgroundImage: "url(/images/hero.jpg)" }}
    >
      <div className="cta mx-auto mt-2">
        <h1 className="mt-0 mb-4">
          Inicio de sesión
          <span className="dot"></span>
        </h1>
        <p className="mb-4">{herosectionData.aboutMe}</p>
       


        <form className="contact-form">
          <div className="row">
            <div className="column col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Usuario"
                  onChange={handleChangeUser}
                  required
                />
              </div>
            </div>

            <div className="column col-md-6">
              
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  placeholder="Contraseña"
                  onChange={handleChangePassword}
                  required
                />
              </div>
            </div>
    
          </div>

          {handleAlerts()}

          <button
            type="button"
            className="btn btn-default"
            onClick={login}
          >
            <i className="icon-user"></i>ENTRAR
          </button>
        </form>
        <p>&nbsp;</p>
<p>¿No tienes cuenta aún? Contacta con nosotros para asegurar toda tu flota con coches de sustitución garantizados, es totalmente GRATIS!<br/>
 <a href="https://empresasdev.amiggoapp.com/registro" className="btn btn-default mr-3">Solicitar registro</a></p>
        
      </div>
      <div className="overlay"></div>
    </section>
  );
}

export default Herosection;
