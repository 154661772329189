import React, {useEffect} from "react";
import Layout2 from "../components/Layout/Layout2";
import SectionHeading from "../components/Items/SectionHeading";
import ContactSection from "../components/Sections/leasingdocs";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const actualType = new URLSearchParams(document.location.search).has("type") ? new URLSearchParams(document.location.search).get("type") : "unknown";

  return (
    <Layout2>
      <section className="shadow-blue white-bg padding mt-0">
        <SectionHeading title={actualType == "unknown"? ("Enviar documentos leasing - seguro, siniestro o reparación"): ("Enviar documentos " + actualType)} />
        <ContactSection />
      </section>
    </Layout2>
  );
}

export default Contact;
