import getCookie from './getCookie';
import GLOBALS from './globals';
const fetch = require('node-fetch'); // <- cargamos el módulo http
/**
 * @param {string} path - ruta (sin dominio)
 * @param {object} body - diccionario de datos post de la petición
 * @param {object} onResponse - diccionario de function(body) cuyas keys son los códigos HTTP, el código 0 corresponde al default (los códigos que no se manejen individualmente pasarán a ser manejados por la función asociada al código 0 Y NO RECIBEN NINGÚN PARÁMETRO POR LA FUNCIÓN)
 */
async function POST(path,body,onResponse,isFile=false){
  const globals = await GLOBALS;
  let requestOptions = {
    crossDomain: true,
    method: 'POST',
    body: JSON.stringify(body),
    mode: 'cors',
    cache: 'default',
    headers: {
      "Access-Control-Allow-Origin": globals.backend_url_base,
      "Content-Type": "Application/json",
      "Access-Control-Allow-Credentials": "true"
    }
  };
  if(document.cookie.includes("session_id")) requestOptions.headers.Authentication = getCookie("session_id");

  try {
    const res = await fetch(`${globals.backend_url_base}/api${path}`, requestOptions);
  
    if (res.status in onResponse) {
      onResponse[res.status](isFile ? await res.blob() : await res.json());
    } else if (0 in onResponse) {
      onResponse[0]();
    }
  } catch (e) {
    if (0 in onResponse) {
      onResponse[0]();
    }
  }
  
}

export default POST;