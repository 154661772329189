
import React, { useState, useEffect } from "react";
import GET from "../../utils/get";

function Contact({ logged }) {
  const [formdata, setFormdata] = useState({
    name: new URLSearchParams(document.location.search).get("plate")
  });

  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  const[token, setToken] = useState("");
  const hasToken = new URLSearchParams(document.location.search).has("token");
  

  useEffect( () => {
    if(hasToken){
      setToken(new URLSearchParams(document.location.search).get("token"))
    }
    else if(!logged && !hasToken){
      window.location.href = "/"
    }
  }, [logged,hasToken]);

  const submitHandler = (event) => {
    event.preventDefault();

    if (!event.target[0].value || !event.target[1].value || (!event.target[2].value && !hasToken)) {
      setError(true);
      setMessage("Debes rellenar todos los campos");
    } else if((event.target[1].value !== event.target[2].value && logged) || (event.target[0].value !== event.target[1].value && !logged && hasToken)){
      setError(true);
      setMessage("Las contraseñas nuevas no coinciden");
    } else if(event.target[0].value === event.target[1].value && !hasToken && logged){
      setError(true);
      setMessage("La nueva contraseña es igual a la anterior");
    } else if(!hasToken && logged){
      GET("/change_password",{"old_password":event.target[0].value,"new_password":event.target[1].value},{
        401: body => {
          setError(true);
          setMessage(body.error);
        },
        400: body => {
          setError(true);
          setMessage(body.error);
        },
        200: body => {
          setError(false);
          setMessage("Contraseña cambiada correctamente!");
        }
      })
    }
    else if(hasToken){
      GET("/change_password/" + token,{"new_password":event.target[1].value},{
        401: body => {
          setError(true);
          setMessage(body.error);
        },
        400: body => {
          setError(true);
          setMessage(body.error);
        },
        200: body => {
          setError(false);
          setMessage("Contraseña restablecida correctamente!");
          setTimeout(function(){
            window.location.href = "/";
          }, 2400);
        }
      })
    }
  };

  const handleChange = (event) => {
    setFormdata({
      ...formdata,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleAlerts = () => {
    if (error && message) {
      return <div className="alert alert-danger mt-4">{message}</div>;
    } else if (!error && message) {
      return <div className="alert alert-success mt-4">{message}</div>;
    } else {
      return null;
    }
  };

  return (
    <div className="row reverse">
      <div className="col-md-4 mb-4 mb-md-0">
      
      </div>

      {logged || hasToken?(<div className="col-md-8">
      {!hasToken?(<div><h5>Cambiar contraseña</h5><p>Introduce tu contraseña actual y luego la nueva confirmandola:</p></div>):
      (<div><h5>Restablecer mi contraseña</h5><p>Introduce tu nueva constraseña confirmandola:</p></div>)}
        <form className="contact-form" onSubmit={submitHandler}>
          <div className="row">
            {!hasToken?<div className="column col-md-6">
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  name="actualPass"
                  placeholder="Contraseña actual"
                  onChange={handleChange}
                  value={formdata.pass}
                />
              </div>
            </div>:<span></span>}
            <div>&nbsp;<br></br></div>
            <div className="column col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="newPass"
                  placeholder="Contraseña nueva"
                  onChange={handleChange}
                  value={formdata.newpass}
                />
              </div>
            </div>     <div className="column col-md-6">
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  name="newPassConfirm"
                  placeholder="Confirmar contraseña"
                  onChange={handleChange}
                  value={formdata.newpassConfirm}
                />
              </div>
            </div>

          </div>

          <button
            type="submit"
            name="submit"
            value="Submit"
            className="btn btn-default"
          >
            <i className="icon-paper-plane"></i>CAMBIAR CONTRASEÑA
          </button>
        </form>
        <p>&nbsp;</p> <p>&nbsp;</p>
        {handleAlerts()}
      </div>):(<span></span>)}
    </div>
  );
}

export default Contact;


