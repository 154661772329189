import React, { useState, useEffect } from "react";
import TrackVisibility from "react-on-screen";
import Funfact from "../Items/Funfact";
import GET from "../../utils/get";

const funfactData = [
  {
    id: 1,
    title: "Empresas con movilidad asegurada",
    count: 160,
    icon: "icon-like",
    name: "finished_reports",
  },
  {
    id: 2,
    title: "Alquileres de vehículos gratuitos",
    count: 94,
    icon: "icon-calendar",
    name: "free_rents",
  },
  {
    id: 3,
    title: "Empleados sin interrupciones",
    count: 540,
    icon: "icon-emotsmile",
    name: "happy_customers",
  },
  {
    id: 4,
    title: "Costes reducidos en alquiler",
    count: 4550,
    icon: "icon-trophy",
    name: "reduced_cost",
  },
];

function Funfacts() {

  const [funFacts,setFunFacts] = useState([]);

  useEffect(() => {
    GET("/get_funfacts",{},{
      200: body => {
        setFunFacts(body);
      }
    })
    
  }, []);

  return (
    <section className="shadow-dark color-white background parallax padding-50">
      <div className="row relative z-1 -mt-50">
        {funfactData.map((funfact) => (
          <div className="col-md-3 col-sm-6 mt-50" key={funfact.id}>
            <TrackVisibility once>
              <Funfact funfact={funfact} realValue={parseInt(funFacts[funfact.name])} />
            </TrackVisibility>
          </div>
        ))}
      </div>
      <div className="overlay"></div>
    </section>
  );
}

export default Funfacts;
