import React, {useEffect} from "react";
import Layout2 from "../components/Layout/Layout";
import { Element } from "react-scroll";
import Login from "../components/Sections/Login";

function Resume() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout2>
         <Element name="section-home">
        <Login />
      </Element>
    </Layout2>
  );
}

export default Resume;
