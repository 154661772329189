import React from "react";

function Testimonial({
  testimonial: { content, authorImage, authorName, authorStatus },
}) {
  return (
    <div className="testimonial-item">
  
    
      <div className="testimonial-details">
        <div className="thumb">
          <img src={authorImage} alt="customer-name" />
        </div>
        <div className="info">
          <h4>{authorName}</h4>
          <span>{authorStatus}</span>
        </div>
      </div>
      <p>&nbsp;</p>
      <p>{content}</p>
    </div>
  );
}

export default Testimonial;
