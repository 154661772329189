
import React, { useEffect, useState } from "react";
import POST from "../../utils/post";
import GET from "../../utils/get";
import GLOBALS from "../../utils/globals";
import getCookie from "../../utils/getCookie";
import JSON5 from "json5";

const contactData = {
  phone: ["+34 655 22 33 44"],
  email: ["partes@amiggoapp.com"],
  location: "Local 41 Alcobendas, Madrid",
};

function Contact() {
  const [formdata, setFormdata] = useState({
    name: ""
  });
  const hasError = new URLSearchParams(document.location.search).has("unuploaded_files");
  const hasOtherError = new URLSearchParams(document.location.search).has("error");
  const hasMessage = new URLSearchParams(document.location.search).has("message");
  const getMessage = ()=>{
    console.log(hasMessage ? (
      hasError || hasOtherError? new URLSearchParams(document.location.search).get("message")+JSON5.parse(new URLSearchParams(document.location.search).get("unuploaded_files")).join(", ")
                : new URLSearchParams(document.location.search).get("message"))
      : "");
    return hasMessage ? (
      hasError ? new URLSearchParams(document.location.search).get("message")+JSON5.parse(new URLSearchParams(document.location.search).get("unuploaded_files")).join(", ")
                : new URLSearchParams(document.location.search).get("message"))
      : "";
  }

  const actualPlate = new URLSearchParams(document.location.search).has("plate") ? new URLSearchParams(document.location.search).get("plate") : "unknown";
  const actualType = new URLSearchParams(document.location.search).has("type") ? new URLSearchParams(document.location.search).get("type") : "unknown";

  const [error, setError] = useState(hasError);
  const [message, setMessage] = useState(getMessage());

  const [unverifiedVehicles, setUnverifiedVehicles] = useState([]);
  const [unverifiedReportVehicles, setUnverifiedReportVehicles] = useState([]);
  const [missingRepairDocsVehicles, setMissingRepairDocsVehicles] = useState([]);
  
  const [globals, setGlobals] = useState({backend_url_base: ""});

  useEffect( async () => {
    setGlobals(await GLOBALS);
    GET("/get_unverified_cars",{},{
      200: body => {
        setUnverifiedVehicles(body);
      }
    })

    GET("/get_onrepair_cars",{},{
      200: body => {
        setUnverifiedReportVehicles(body);
      }
    })

    GET("/get_missing_repair_docs_cars",{},{
      200: body => {
        setMissingRepairDocsVehicles(body);
      }
    })
    
  }, []);

  const submitHandler = (event) => {
    event.preventDefault();

    if (event.target[0].files.length == 0) {
      setError(true);
      setMessage("Es necesario subir al menos un archivo");
    } else {
      POST("/upload_leasing",{"myFormData":event.target[0].files, "plate":actualPlate, "type":actualType},{
        400: body => {
          setError(true);
          setMessage(body.error? body.error:"Error");
        },
        200: body => {
          setError(false);
          setMessage("Archivos enviados correctamente!");
        },
        302: body => {
          setError(false);
          setMessage("Archivos enviados correctamente con redirección!");
        },
        206: body => {
          setError(false);
          setMessage("Algunos archivos enviados correctamente! Estos archivos no se han podido subir: " + body.unuploaded_files);
        }
      },true);
      setError(false);
      setMessage(JSON.stringify(event.target[0].files[0]));
    }
  };

  const mostrarVehiculos = async (event) => {
    event.preventDefault();
    let vehiclesUnverified = document.getElementsByClassName("unverified");
    let vehiclesOnrepair = document.getElementsByClassName("onrepair");
    let vehiclesMissingRepairDocs = document.getElementsByClassName("missingRepair");
    let allReports = document.getElementsByClassName("chose-report");
    document.getElementById("vehicleDefault").selected = true;
    for(let i=0;i<allReports.length;i++){
      allReports[i].hidden = true;
    }
    if(document.getElementById("reportDefault"))
      document.getElementById("reportDefault").selected = true;
    
    if(event.currentTarget.value == "leasing"){
      for(let i=0;i<vehiclesUnverified.length;i++){
        vehiclesUnverified[i].hidden = false;
      }
      for(let i=0;i<vehiclesOnrepair.length;i++){
        vehiclesOnrepair[i].hidden = true;
      }
      for(let i=0;i<vehiclesMissingRepairDocs.length;i++){
        vehiclesMissingRepairDocs[i].hidden = true;
      }
    }
    if(event.currentTarget.value == "siniestro"){
      for(let i=0;i<vehiclesUnverified.length;i++){
        vehiclesUnverified[i].hidden = true;
      }
      for(let i=0;i<vehiclesOnrepair.length;i++){
        vehiclesOnrepair[i].hidden = false;
      }
      for(let i=0;i<vehiclesMissingRepairDocs.length;i++){
        vehiclesMissingRepairDocs[i].hidden = true;
      }
    }
    if(event.currentTarget.value == "reparacion"){
      for(let i=0;i<vehiclesMissingRepairDocs.length;i++){
        vehiclesMissingRepairDocs[i].hidden = false;
      }
      for(let i=0;i<vehiclesUnverified.length;i++){
        vehiclesUnverified[i].hidden = true;
      }
      for(let i=0;i<vehiclesOnrepair.length;i++){
        vehiclesOnrepair[i].hidden = true;
      }
      document.getElementById("reportDefault").selected = true;
    }
  }

  const mostrarPartes = async (event) => {
    event.preventDefault();

    let allReports = document.getElementsByClassName("chose-report");
    let vehicleReports = document.getElementsByClassName(event.target.value);
    document.getElementById("reportDefault").selected = true;

    for(let i=0;i<allReports.length;i++){
      allReports[i].hidden = true;
    }
    for(let i=0;i<vehicleReports.length;i++){
      vehicleReports[i].hidden = false;
    }
  }

  const handleChange = (event) => {
    setFormdata({
      ...formdata,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleAlerts = () => {
    if (error && message) {
      return <div className="alert alert-danger mt-4">{message}</div>;
    } else if (!error && message) {
      return <div className="alert alert-success mt-4">{message}</div>;
    } else {
      return null;
    }
  };

  return (
    <div className="row reverse">
      <div className="col-md-4 mb-4 mb-md-0">

        </div>

      <div className="col-md-8">
      <h5>Enviar documentos {actualPlate == "unknown"?<span></span>:<b>para la matrícula {actualPlate}</b>}</h5>
        <form className="contact-form" action={globals.backend_url_base + "/upload_leasing"} id="my-dropzone" method="POST" encType="multipart/form-data">
          <div className="row">
            <div className="column col-md-6">
              
              <div className="form-group">
                {actualType == "unknown"?(
                  <select className="form-control" required id="type" name="type" onChange={actualPlate == "unknown"? (event => mostrarVehiculos(event)):(null)}>
                    <option selected disabled value="">ELIGE UN TIPO DE DOCUMENTO</option>
                    <option value="leasing" hidden={actualPlate == "unknown"? (false): (unverifiedVehicles.filter(car => car.plate == actualPlate).length > 0? (false):(true))}>LEASING-SEGURO</option>
                    <option value="siniestro" hidden={actualPlate == "unknown"? (false): (unverifiedReportVehicles.filter(car => car.plate == actualPlate).length > 0? (false):(true))}>SINIESTRO</option>
                    <option value="reparacion" hidden={actualPlate == "unknown"? (false): (missingRepairDocsVehicles.filter(car => car.plate == actualPlate).length > 0? (false):(true))}>FIN REPARACIÓN</option>
                  </select>
                ):(<input type="hidden" name="type" id="type" value={actualType}/>)}
              </div>

              <div className="form-group">
                {actualPlate == "unknown"?(
                  <select className="form-control" required name="plate" id="plate" onChange={actualType == "unknown" || actualType == "reparacion"? (event => mostrarPartes(event)):(null)}>
                    <option id="vehicleDefault" selected disabled value="">ELIGE UN VEHÍCULO PARA SUBIR SU DOCUMENTACIÓN</option>
                    {unverifiedVehicles.map((vehicle) => (
                      <option value={vehicle.plate} key={vehicle.id} className="unverified" hidden={actualType == "unknown"? (true):(actualType == "leasing"? (false):(true))}>{vehicle.plate}</option>
                    ))}
                    {unverifiedReportVehicles.map((vehicle) => (
                      <option value={vehicle.plate} key={vehicle.id} className="onrepair" hidden={actualType == "unknown"? (true):(actualType == "siniestro"? (false):(true))}>{vehicle.plate}</option>
                    ))}
                    {missingRepairDocsVehicles.map((vehicle) => (
                      <option value={vehicle.plate} key={vehicle.id} className="missingRepair" hidden={actualType == "unknown"? (true):(actualType == "reparacion"? (false):(true))}>{vehicle.plate}</option>
                    ))}
                  </select>
                ):(<input type="hidden" name="plate" id="plate" value={actualPlate}/>)}
              </div>

              <div className="form-group missingRepair chose_report" hidden={actualType == "unknown"? (true):(actualType == "reparacion"? (false):(true))}>
                <select className="form-control" required={actualType == "unknown"? (false):(actualType == "reparacion"? (true):(false))} name="choose_report">
                  <option id="reportDefault" selected disabled value="">ELIGE SOBRE QUÉ PARTE SUBIR LA DOCUMENTACIÓN</option>
                  {actualPlate == "unknown"?(missingRepairDocsVehicles.map((vehicle) => (
                    vehicle.finished_reports.map((finished) => (
                      <option hidden="true" value={finished.creation_date} key={finished.id} className={"chose-report " + vehicle.plate}>Parte empezado el día {finished.creation_date} con identificador id-{finished.id}</option>
                    ))
                  ))):(missingRepairDocsVehicles.filter(vehicle => vehicle.plate == actualPlate).map((filteredVehicle) => (
                    filteredVehicle.finished_reports.map((finished) => (
                      <option value={finished.creation_date} key={finished.id} className={"chose-report " + filteredVehicle.plate}>Parte empezado el día {finished.creation_date} con identificador id-{finished.id}</option>
                    ))
                  )))}
                </select>
              </div>
            
              <p>Arrastra o selecciona los archivos desde tu ordenador para enviar los documentos {actualType == "unknown"?(""):(actualType == "leasing"? ("del leasing o seguro"):(actualType == "siniestro"? ("del " + actualType):("de la " + actualType)))} de los vehiculos de tu flota que quieras gestionar a través de AmiGGoapp:</p>
              <div className="form-group">
                <input
                  type="file"
                  className="form-control"
                  placeholder="Documentos"
                  onChange={handleChange}
                  name="myFormData"
                  id="myFormData"
                  accept=".pdf,.jpg,.jpeg,.png,.doc,.docx,.bmp,.eps,.wps,.txt,.odt,.rtf,.svg"
                  required
                  multiple
                />
                <input type="hidden" name="redirect" id="redirect" value={window.location.href}/>
                <input type="hidden" name="Authentication" id="Authentication" value={getCookie("session_id")}/>
                
              </div>
            </div>
            <div>&nbsp;<br></br></div>

            
          </div>
<p>  {handleAlerts()}</p>
          <button
            type="submit"
            name="submit"
            value="Submit"
            className="btn btn-default"
          >
            <i className="icon-paper-plane"></i>ENVIAR
          </button>
        </form>
        <p>&nbsp;</p>
      
      </div>
    </div>
  );
}

export default Contact;


