import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

function SectionHeading({ title }) {
  return (
    <>
     <div className="vehiculos"><div className="titulo"> <h3 className="section-title">{title}</h3></div>
     <div className="botones">
      <a href="/services" data-tooltip-id="mas"><i className="icon-plus bononesicon"></i></a>
      &nbsp;&nbsp;&nbsp;<a href="/leasing" data-tooltip-id="leasin"><i className="icon-book-open bononesicon" alt="leasing"></i></a>
     
     </div></div>
      <div className="spacer" data-height="80"></div>
      <ReactTooltip
        id="mas"
        place="bottom"
        variant="info"
        content="Añadir vehículos"
      />
           <ReactTooltip
        id="leasin"
        place="top"
        variant="info"
        content="Enviar documentación de vehículos"
      />
    </>
  );
}

export default SectionHeading;
