import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { confirmable, createConfirmation } from "react-confirm";

const Confirmation = ({
  modalHeader = false,
  modalFooter = false,
  footerButtons = false,
  headerInfo,
  footerInfo,
  okLabel,
  cancelLabel,
  title = "",
  closeButton = false,
  confirmation,
  inputType = "none",
  onchange = null,
  show = true,
  proceed,
  enableEscape = true
}) => {
  return (
    <div className="static-modal">
      <Modal
        animation={false}
        show={show}
        onHide={() => proceed(false)}
        backdrop={enableEscape ? true : "static"}
        keyboard={enableEscape}
      >

        {modalHeader?
        <Modal.Header>
          <div>
            {title != ""? <Modal.Title className="modal-title">{title}</Modal.Title>:<span></span>}
            {headerInfo}
          </div> 
          {closeButton? <Button className='plus-icon-delete' onClick={() => proceed(false)}>X</Button> : <span></span>}
        </Modal.Header>:<span></span>}

        <Modal.Body>
            {confirmation}
            <br/>
            {inputType == "none"? (<span></span>):(<input id="modal-input" type={inputType} min="1" onChange={onchange}/>)}
            <br/>{inputType == "none"? (<span></span>):(<span id="modal-input-span"></span>)}
        </Modal.Body>

        {modalFooter?
        <Modal.Footer>
          {footerInfo}
          {footerButtons?<Button className="plus-icon-delete" onClick={() => proceed(false)}>{cancelLabel}</Button>:<span></span>}
          {footerButtons?<Button
            className="button-l"
            bsstyle="primary"
            onClick={() => proceed(true)}
          >
            {okLabel}
          </Button>:<span></span>}
        </Modal.Footer>:<span></span>}

      </Modal>
    </div>
  );
};

Confirmation.propTypes = {
  modalHeader: PropTypes.bool,
  modalFooter: PropTypes.bool,
  footerButtons: PropTypes.bool,
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  closeButton: PropTypes.bool,
  confirmation: PropTypes.element,
  headerInfo: PropTypes.element,
  footerInfo: PropTypes.element,
  inputType: PropTypes.string,
  onchange: PropTypes.func,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  enableEscape: PropTypes.bool
};

export function confirm(
    confirmation,
    modalHeader,
    title,
    closeButton,
    headerInfo,
    modalFooter,
    footerInfo,
    footerButtons,
    okLabel,
    cancelLabel,
    inputType,
    onchange,
    enableEscape,
    options = {}
) {
  return createConfirmation(confirmable(Confirmation))({
    confirmation,
    modalHeader,
    title,
    closeButton,
    headerInfo,
    modalFooter,
    footerInfo,
    footerButtons,
    okLabel,
    cancelLabel,
    inputType,
    onchange,
    enableEscape,
    ...options
  });
}